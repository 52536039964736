import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import formatname from "../../utils/formatInputNumberName";
const InputNumber = ({ name, min, max, object, onChange, question }) => {

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(typeof object?.prestation !== "object")

  const increment = () => {
    if (question) return
    if (typeof max === 'number' && value >= max) return;

    setValue(value + 1);
  }

  const decrement = () => {
    if (question) return
    if (value === 1) { setValue(0); onChange(0, object) }

    if (typeof min === 'number' && value <= min) return;

    setValue(value - 1);
  }

  useEffect(() => {
    (typeof object?.prestation === "object") && onChange(value, object)
  }, [value]);

  useEffect(() => {
    setValue(0)
  }, [question])

  useEffect(() => {
    if (typeof object?.prestation !== "object") setLoading(false)
  }, [object?.prestation])

  return (
    <motion.div
      initial={{ x: 20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-row justify-between items-center my-2 sm:my-3"
    >
      <label className="text-textgray-dark/80 text-lg font-medium mr-6 sm:mr-16">{formatname(name)}</label>
      <div className="input-number">
        <button type="button" onClick={decrement} disabled={value === min || value === 0}>&minus;</button>
        <span className="mx-1">{value}</span>
        <button type="button" onClick={increment}>&#43;</button>
      </div>
    </motion.div>
  );
};

export default InputNumber;
