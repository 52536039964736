import React, { useEffect, useState } from 'react';
import { useContext } from "react";
import Context from "../../context/context";
import { motion } from "framer-motion";
import formatNumber from "../../utils/formatPrice";
import timeConvert from "../../utils/timeConvert";

const DevisPrestation = ({ currentStep, setCurrentStep }) => {

  const [isCollapsedInfo, setIsCollapsedInfo] = useState(false)
  const [isCollapsedInclus, setIsCollapsedInclus] = useState(false)

  useEffect(() => {
    let btn = document.querySelector('#collapseInclus')

    var ob = new MutationObserver(function (event) {

      (event[0]?.target?.className?.includes("collapse show") || event[0]?.target?.className?.includes("collapsing")) ? setIsCollapsedInclus(true) : setIsCollapsedInclus(false)
    });
    ob.observe(btn, {
      attributes: true,
      attributeFilter: ["class"]
    });
  })

  useEffect(() => {
    let btn = document.querySelector('#collapseInfo')

    var ob = new MutationObserver(function (event) {

      (event[0]?.target?.className?.includes("collapse show") || event[0]?.target?.className?.includes("collapsing")) ? setIsCollapsedInfo(true) : setIsCollapsedInfo(false)
    });
    ob.observe(btn, {
      attributes: true,
      attributeFilter: ["class"]
    });
  }, [])

  useEffect(() => {
    window.scroll({
      top: 120,
      left: 0,
      behavior: 'smooth'
    });
  }, [])

  const { prestation, totalPrice, totalHour } = useContext(Context)
  console.log("prestation inside ", prestation)
  return (
    <div className="w-full md:w-[45rem] px-2">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="py-1 flex flex-col lg:flex w-full"
      >
        <div className="flex flex-col gap-3 w-full">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col w-full"
          >
            {/*  <div className="sm:w-96 w-80 mx-auto text-gray-800 underlined relative mb-4 font-medium text-center">
              <motion.h3
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className='text-center self-center font-bold'>Prestations à réaliser par le Pro</motion.h3>
            </div> */}

            {/* <motion.ul className='prestation-list self-start mt-2 mb-6'>
              {
                prestation.map((pres) => (
                  <motion.li key={pres?.prestation?._id} className='text-gray-900 font-bold'>{pres?.prestation?.libelle} {/* <span className='text-green-400 font-semibold'> x {pres.unite}</span> */
            /*}
                  </motion.li>
                ))
              }
            </motion.ul>
            */}
            <h3 className="font-semibold text-xl text-textgray-dark/80 w-fit underlinedYellow relative mt-1">
              Ce que le pro fera
            </h3>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="accordion accordion-flush my-3"
              id="accordionFlushExample"
            >
              {prestation.map((pres) => (
                <div
                  className="accordion-item border-b border-gray-500 border-dashed rounded-none bg-white"
                  key={pres?.prestation?._id}
                >
                  <h2
                    className="accordion-header mb-0"
                    id={`flush-heading${pres?.prestation?._id}`}
                  >
                    <button
                      className="accordion-button collapsed border-0 relative flex items-center w-full py-2 text-xl text-textgray-default font-semibold text-left rounded-none transition focus:outline-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${pres?.prestation?._id}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapse${pres?.prestation?._id}`}
                    >
                      {pres?.prestation?.libelle}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${pres?.prestation?._id}`}
                    className="accordion-collapse border-0 collapse"
                    aria-labelledby={`flush-heading${pres?.prestation?._id}`}
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body py-2 px-2 text-lg">
                      {pres?.prestation?.description}
                    </div>
                  </div>
                </div>
              ))}
            </motion.div>

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col mt-1"
            >
              <div className="flex flex-col gap-3">
                <div className='flex flex-col'>
                  <div className="flex flex-row justify-between gap-1 sm:gap-2">
                    <span className="text-textgray-dark/80 flex flex-col">
                      Durée{" "}
                    </span>
                    <span className="font-bold pl-px">
                      {_.find(
                        prestation.map((p) => p.prestation),
                        function (p) {
                          return p?.presForfaitaire;
                        }
                      )
                        ? _.find(
                          prestation.map((p) => p.prestation),
                          function (p) {
                            return p?.presForfaitaire;
                          }
                        ).presForfaitaireDesc
                        : timeConvert(totalHour)}
                    </span>
                  </div>
                  <span className="text-sm text-textgray-dark/80">Durée estimé des travaux</span>
                </div>

                <div className='flex flex-col gap-0'>
                  <div className="flex flex-row justify-between gap-1 sm:gap-2">
                    <span className="text-textgray-dark/80">
                      Tarif
                    </span>
                    <span className="font-bold pl-px">
                      {formatNumber(totalPrice)} Dhs
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-textgray-dark/80 font-thin text-sm">
                      Toutes taxes comprises - hors fourniture ou matériel de
                      remplacement
                    </span>
                    <span className="text-textgray-dark/80 font-thin text-sm">
                      Inclus frais de déplacement 50 Dhs toutes taxes
                      comprises
                    </span>
                  </div>
                </div>

              </div>
            </motion.div>

          </motion.div>

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <button
              className="w-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseInclus"
              aria-expanded="false"
              aria-controls="collapseInclus"
            >
              <div className="flex justify-between mb-4 items-center w-full">
                <h3 className="font-semibold text-xl text-textgray-dark/80 text-start underlinedYellow relative pb-1">
                  Inclus dans la prestation
                </h3>

                <span className="inline-block underline text-blue-600 font-medium text-xs leading-tight">
                  {isCollapsedInclus
                    ? "- Masquer le détail"
                    : "+ Afficher le détail"}
                </span>
              </div>
            </button>
            <div
              className="collapse"
              id="collapseInclus"
            >
              <ul className="checkList relative ml-1 text-base leading-8">
                <li>Frais de déplacement</li>
                <li>Assistance client 6/7J</li>
                <li>Nettoyage après intervention</li>
                <li>Garantie “Satisfait ou refait” jusqu’à 6 mois</li>
              </ul>
            </div>
          </motion.div>

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <button
              className="w-full mb-4 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseInfo"
              aria-expanded="false"
              aria-controls="collapseInfo"
            >
              <div className="flex justify-between  items-center w-full">
                <h3 className="font-semibold text-xl text-textgray-dark/80 underlinedYellow relative">
                  Bon à savoir
                </h3>

                <span className="inline-block underline text-blue-600 font-medium text-xs leading-tight">
                  {isCollapsedInfo
                    ? "- Masquer le détail"
                    : "+ Afficher le détail"}
                </span>
              </div>
            </button>

            <div
              className="collapse pl-2 text-base"
              id="collapseInfo"
            >
              <h5 className="font-bold my-2">
                Que se passe-t’il si la durée réelle est différente de la durée
                estimée ?
              </h5>
              <span className="break-words">
                Une fois sur place, le Pro analysera la situation et vous pourra
                ajuster le cas échéant le devis définitif. En cas de
                non-acceptation du devis, aucun frais n’est engagé (ni
                déplacement, ni établissement du devis)
              </span>
              <h5 className="font-bold my-2">
                Et si j’ai besoin de fourniture
              </h5>
              <span>
                Le devis ne comprend pas la fourniture. Si la prestation
                nécessite une fourniture, elle sera identifiée par le Pro sur
                place et chiffrée sur place par nos équipes. Le prix vous sera
                communiqué et votre accord sera attendu pour continuer la
                prestation
              </span>
              <h5 className="font-bold my-2">
                Garantie “Satisfait ou refait” jusqu’à 6 mois
              </h5>
              <span>
                Votre prestation est couverte par une garantie qui vous offre la
                possibilité de voir la prestation refaite si elle n'est pas
                conforme à ce qui avait été commandé initialement.
              </span>
              <h5 className="font-bold my-2">
                Est-ce qu’il y a un délai pour valider le devis ?
              </h5>
              <span>
                Tous nos devis sont valables 15 jours pour des raisons de
                fluctuations de la fourniture et des frais logisitiques.
              </span>
            </div>
          </motion.div>

          <motion.button
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-[#324EA1] w-full sm:w-96 text-white rounded-md sm:rounded-2xl py-2 px-10 my-2 self-center"
            onClick={() => setCurrentStep(2)}
          >
            Demander une intervention
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
}

export default DevisPrestation;
