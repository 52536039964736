// import React, { useEffect, useState, useRef, useContext } from 'react';
// import Select from 'react-select'
// import _ from "lodash"
// import { getAllPrestations } from '../../services/prestation'
// import customStyles from '../../Constants/selectInputStyle'
// import Context from '../../context/context';
// import axios from "axios"
// import { createDemande } from '../../services/demande';
// import { useNavigate } from 'react-router-dom';
// // import { createDevis } from '../../services/devis'
// // import { createAdress } from "../../services/addresse"


// /* const customStyles = {
//   option: (provided, state) => ({
//     ...provided,
//     fontSize: "0.8rem"
//   }),
//   control: (provided, state) => ({
//     ...provided,
//     backgroundColor: "white",
//     border: "solid 1px #404040",
//     borderRadius: "8px",
//   }),
//   multiValue: (provided, state) => ({
//     ...provided,
//     backgroundColor: "#788CC7",
//     border: "solid 1px #788CC7",
//     borderRadius: "5px",
//     color: "white"
//   }),
//   multiValueLabel: (provided, state) => ({
//     ...provided,
//     color: "white",
//     paddingLeft: "1rem",
//     paddingRight: "1rem",
//     fontSize: "0.75rem"
//   }),
//   multiValueRemove: (provided, state) => ({
//     ...provided,
//   }),
//   singleValue: (provided, state) => {
//     const opacity = state.isDisabled ? 0.5 : 1;
//     const transition = 'opacity 300ms';
//     return { ...provided, opacity, transition };
//   }
// }
//  */

// const DemandePersonalise = ({ step, path }) => {
//   const [prestations, setPrestations] = useState([])
//   const [img, setImg] = useState(null)
//   const { demandePerso, setDemandePerso } = useContext(Context)
//   const [fileUrls, setFileUrls] = useState([])
//   const [prestationLibelle, setPrestationLibelle] = useState()
//   const navigate = useNavigate()
//   const fetchData = async () => {
//     let result = await getAllPrestations()
//     let prestation = []
//     let filtredprestation = _.uniqBy(result, "SearchLibelle")
//     console.log(filtredprestation);
//     filtredprestation.forEach(prest => prest.SearchLibelle && prestation.push({ value: prest, label: prest.SearchLibelle }))
//     console.log(prestation);
//     setPrestations(prestation)
//   }

//   const handleFileInputChange = async (e) => {
//     console.log(e.target.files[0]);
//     var file = e.target.files[0]//this.refs.file.files[0];
//     var reader = new FileReader();
//     var url = reader.readAsDataURL(file);
//     if (e.target.files[0]) {
//       const formDataFile = new FormData();
//       formDataFile.append("media", e.target.files[0]);
//       console.log(e.target.files[0])
//       try {
//         const response = await axios.post(`https://microservices.saweblia.ma:11111/uploadFile`, formDataFile)
//         console.log("response", response)
//         response && setFileUrls([...fileUrls, response.data.mediaPath])
//         reader.onloadend = function (el) {
//           console.log([reader.result]);
//           setImg({ ...img, [e.target.name]: [reader.result] });

//         }.bind(this);
//         console.log(url)

//       } catch (error) {
//         console.log(error)
//       }
//     }


//   }

//   useEffect(() => {
//     step.demandepersonalise && fetchData()
//   }, [])

//   useEffect(() => {
//     path.forEach(elm => {
//       elm.libelle && console.log("libelle ", elm.libelle);
//       elm.question && console.log("question ", elm.question);
//       elm.stepName && console.log("stepName ", elm.stepName);
//     })
//   }, [])

//   const handleDemandePerso = (e) => {
//     setDemandePerso({ ...demandePerso, [e.target.name]: e.target.value })
//   }

//   const handlePrestations = (e) => {
//     console.log(e)
//     const searchLibelle = e.map(el => el.label).join(" , ")
//     setPrestationLibelle(`${searchLibelle}`)
//     const univers = _.uniqBy(e.flatMap(el => el.value.services[0]))
//     console.log("univers",univers)
//     setDemandePerso({ ...demandePerso, services:[...univers] })
//   }

//   const handleEnrigistrerPerso = () => {
//     let pathArray = []
//     path && path.forEach(elm => {
//       elm.libelle && pathArray.push(elm.libelle);
//       elm.question && pathArray.push(elm.question);
//     })
//     let comment = `Workflow chemin : ${pathArray.join(" - ")} `
//     console.log(comment)
//     const demande = {
//       commentaire: `${comment} > ${demandePerso?.commentaire} > ${prestationLibelle}`,
//       services: demandePerso.services,
//       files: fileUrls,
//     }
//     setDemandePerso(demande)
//     if (demandePerso) {
//       console.log(demandePerso, prestationLibelle, fileUrls, demande)
//       navigate("/identification?type=demande")
//     }
//   }

//   return (
//     <div className="flex flex-col">
//       <h4 className="StepTitle relative text-center font-semibold w-full text-2xl mb-12">Demande personnalisée</h4>
//       <div className='mt-4 flex flex-col'>
//         <label className='my-1'>Quel est votre besoin / problème ?*</label>
//         <textarea name="commentaire" id="" cols="50" rows="4" onChange={handleDemandePerso} className='pl-2 border border-gray-500 rounded-lg' placeholder='commentaire'></textarea>
//       </div>
//       {
//         step?.demandepersonalise &&
//         <div className='mt-4 flex flex-col max-w-lg'>
//           <label htmlFor="">Pouvez-vous préciser la (ou les) prestation(s) demandée(s) ?*</label>
//           <Select options={prestations}
//             isMulti={true}
//             styles={customStyles}
//             closeMenuOnSelect={false}
//             onChange={(e) => { handlePrestations(e) }}
//             placeholder="Sélectionner une ou plusieurs prestations"
//           />
//         </div>
//       }
//       <div className='mt-4 flex flex-col'>
//         <span className='my-2'>Pouvez-vous joindre des photos ?</span>
//         <div className='flex gap-10'>
//           <label htmlFor='file-upload' className='cursor-pointer h-fit w-fit p-1 flex items-center justify-center border border-dotted border-gray-900 rounded-lg'>
//             {
//               img?.img1 ?
//                 <img src={img?.img1} className="h-28 w-40 object-contain" />
//                 :
//                 <span className='h-28 w-40 flex items-center justify-center'>+</span>
//             }

//           </label>
//           <input type="file" name="img1" id="file-upload" className='hidden' onChange={handleFileInputChange} />
//           <label htmlFor='file-upload-2' className='cursor-pointer h-fit w-fit p-1 flex items-center justify-center border border-dotted border-gray-900 rounded-lg'>
//             {
//               img?.img2 ?
//                 <>
//                   <img src={img?.img2} className="h-28 w-40 object-contain" />
//                 </>
//                 :
//                 <span className='h-28 w-40 flex items-center justify-center'>+</span>
//             }

//           </label>
//           <input type="file" name="img2" id="file-upload-2" className='hidden' onChange={handleFileInputChange} onClick={e => (e.target.value = null)} />
//           <label htmlFor='file-upload-3' className='cursor-pointer h-fit w-fit p-1 flex items-center justify-center border border-dotted border-gray-900 rounded-lg'>
//             {
//               img?.img3 ?
//                 <img src={img?.img3} className="h-28 w-40 object-contain" />
//                 :
//                 <span className='h-28 w-40 flex items-center justify-center'>+</span>
//             }

//           </label>
//           <input type="file" name="img3" id="file-upload-3" className='hidden' onChange={handleFileInputChange} />
//         </div>
//       </div>
//       <button className='bg-[#324EA1] rounded-2xl py-2 px-10 my-10 self-center text-white'
//         disabled={!demandePerso?.commentaire}
//         onClick={handleEnrigistrerPerso}
//       >Demander une intervention</button>
//     </div>
//   );
// }

// export default DemandePersonalise;

import React, { useEffect, useState, useRef, useContext } from 'react';
import Select from 'react-select'
import _ from "lodash"
import { getAllPrestations } from '../../services/prestation'
import customStyles from '../../Constants/selectInputStyle'
import Context from '../../context/context';
import axios from "axios"
import { createDemande } from '../../services/demande';
import { useNavigate } from 'react-router-dom';
import Identification from '../authFlow/Identification';
import Login from "../authFlow/Login"
import Register from '../authFlow/Register';
import Header from '../../Layout/Header'
import NavBar from '../../Layout/NavBar';
import { useSearchParams } from "react-router-dom"
import DemandeConfirm from '../Devis/DemandeConfirm';
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const DemandePersoForm = ({ count, setCount, stepObject, step, path, postDemandePerso, setPrestationLibelle, prestationLibelle, fileUrls, setFileUrls, demandePerso, setDemandePerso, currentStep, setCurrentStep, TYPE, setPrevStep, setPrestations, prestations, setImg, img, navigate }) => {
  console.log("step:", step, "path:", path)
  const fetchData = async () => {
    let result = await getAllPrestations()
    let prestation = []
    let filtredprestation = _.uniqBy(result, "SearchLibelle")
    console.log(filtredprestation);
    filtredprestation.forEach(prest => prest.SearchLibelle && prestation.push({ value: prest, label: prest.SearchLibelle }))
    console.log(prestation);
    setPrestations([{ value: { _id: "12", services: ["autre"], libelle: "Autre", SearchLibelle: "Autre prestation" }, label: "Autre prestation" }, ...prestation])
  }

  const handleFileInputChange = async (e) => {
    console.log(e.target.files[0]);
    var file = e.target.files[0]//this.refs.file.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    if (e.target.files[0]) {
      const formDataFile = new FormData();
      formDataFile.append("media", e.target.files[0]);
      console.log(e.target.files[0])
      try {

        reader.onloadend = function (el) {
          console.log([reader.result]);
          setImg({ ...img, [e.target.name]: [reader.result] });

        }.bind(this);
        const response = await axios.post(`https://microservices.saweblia.ma:11111/uploadFile`, formDataFile)
        console.log("response", response)
        response && setFileUrls([...fileUrls, response.data.mediaPath])

        console.log(url)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setDemandePerso({ ...demandePerso, fileUrls: fileUrls })
  }, [fileUrls])

  const handleDemandePerso = (e) => {
    setDemandePerso({ ...demandePerso, [e.target.name]: e.target.value })
  }

  const handleEnrigistrerPerso = async () => {
    await delay(200)
    setCurrentStep(1)
  }

  const handlePrestations = (e) => {
    console.log(e)
    const searchLibelle = e.map(el => el.label).join(" , ")
    setPrestationLibelle(`${searchLibelle}`)
    const univers = _.uniqBy(e.flatMap(el => el.value.services[0]))
    console.log("univers", univers)
    setDemandePerso({ ...demandePerso, services: [...univers] })
  }

  return (
    <div className="flex flex-col w-full container my-1 sm:my-3 px-2 md:px-8 mx-auto max-w-[700px] items-center">
      <h4 className="StepTitle relative text-center font-semibold w-full text-2xl mb-6">
        Demande personnalisée
      </h4>
      <div className="mt-4 flex flex-col w-full">
        <label className="mb-2">
          Quel est votre besoin/problème ? (obligatoire)
        </label>
        <textarea
          name="commentaire"
          id=""
          rows="4"
          onChange={handleDemandePerso}
          className="pl-2 pt-3 border border-gray-500 rounded-lg"
          placeholder="Commentaire"
        ></textarea>
      </div>
      {

        stepObject?.demandepersonalise &&
        <div className='mt-4 flex flex-col w-full'>
          <label htmlFor="">Pouvez-vous préciser la (ou les) prestation(s) demandée(s) ?*</label>
          <Select options={prestations}
            isMulti={true}
            styles={customStyles}
            closeMenuOnSelect={false}
            onChange={handlePrestations}
            placeholder="Sélectionner une ou plusieurs prestations"
          />
        </div>
      }

      <div className="mt-4 flex flex-col w-full">
        <span className="mb-2">Pouvez-vous joindre des photos ?</span>
        <div className="flex flex-wrap gap-8 justify-center lg:justify-start">
          <label
            htmlFor="file-upload"
            className="cursor-pointer h-fit w-fit p-1 flex items-center justify-center border border-dotted border-gray-900 rounded-lg"
          >
            {img?.img1 ? (
              <img src={img?.img1} className="h-28 w-40 object-contain" />
            ) : (
              <span className="h-28 w-40 flex items-center justify-center">
                +
              </span>
            )}
          </label>
          <input
            type="file"
            name="img1"
            accept="image/png, image/gif, image/jpeg"
            id="file-upload"
            className="hidden"
            onChange={handleFileInputChange}
          />
          <label
            htmlFor="file-upload-2"
            className="cursor-pointer h-fit w-fit p-1 flex items-center justify-center border border-dotted border-gray-900 rounded-lg"
          >
            {img?.img2 ? (
              <>
                <img src={img?.img2} className="h-28 w-40 object-contain" />
              </>
            ) : (
              <span className="h-28 w-40 flex items-center justify-center">
                +
              </span>
            )}
          </label>
          <input
            type="file"
            name="img2"
            accept="image/png, image/gif, image/jpeg"
            id="file-upload-2"
            className="hidden"
            onChange={handleFileInputChange}
            onClick={(e) => (e.target.value = null)}
          />
          <label
            htmlFor="file-upload-3"
            className="cursor-pointer h-fit w-fit p-1 flex items-center justify-center border border-dotted border-gray-900 rounded-lg"
          >
            {img?.img3 ? (
              <img src={img?.img3} className="h-28 w-40 object-contain" />
            ) : (
              <span className="h-28 w-40 flex items-center justify-center">
                +
              </span>
            )}
          </label>
          <input
            type="file"
            name="img3"
            accept="image/png, image/gif, image/jpeg"
            id="file-upload-3"
            className="hidden"
            onChange={handleFileInputChange}
          />
        </div>
      </div>

      <button
        className={
          demandePerso?.commentaire


            ? `bg-[#324EA1] rounded-2xl py-2 px-10 my-10 self-center text-white`
            : `bg-blue-300 rounded-2xl py-2 px-10 my-10 self-center text-white`
        }
        disabled={
          !demandePerso?.commentaire
        }
        onClick={handleEnrigistrerPerso}
      >
        Demander une intervention
      </button>
    </div>
  );

}
const areEqual = (prevProps, nextProps) => true;

const DemandePersonalise = ({ step, path }) => {
  const [prestations, setPrestations] = useState([])
  const [img, setImg] = useState(null)
  const { demandePerso, setDemandePerso, postDemandePerso, setCount, count } = useContext(Context)
  const [fileUrls, setFileUrls] = useState([])
  const [prestationLibelle, setPrestationLibelle] = useState()
  const [demandeStep, setDemandeStep] = useState(5)
  const [prevStep, setPrevStep] = useState(0)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setDemandePerso({ city: searchParams.get("ville"), ...demandePerso })
  }, [])

  const renderDemandeStep = () => {
    // let step = demandeStep;
    switch (demandeStep) {
      case 5:
        return (
          <div className='flex justify-center mt-4'>
            <DemandePersoForm
              step={demandeStep}
              stepObject={step}
              path={path}
              count={count}
              setCount={setCount}
              navigate={navigate}
              currentStep={demandeStep}
              setCurrentStep={setDemandeStep}
              TYPE={"DEMANDE"}
              setPrevStep={setPrevStep}
              setPrestations={setPrestations}
              prestations={prestations}
              setImg={setImg}
              img={img}
              setDemandePerso={setDemandePerso}
              demandePerso={demandePerso}
              setFileUrls={setFileUrls}
              fileUrls={fileUrls}
              prestationLibelle={prestationLibelle}
              setPrestationLibelle={setPrestationLibelle}
              postDemandePerso={postDemandePerso}
            />
          </div>

        );
      case 1:
        return (
          <Identification
            currentStep={demandeStep}
            setCurrentStep={setDemandeStep}
            TYPE={"DEMANDE"}
            setPrevStep={setPrevStep}
            path={path}
          />
        );
      case 2:
        return (
          <Login
            currentStep={demandeStep}
            setCurrentStep={setDemandeStep}
            TYPE={"DEMANDE"}
            setPrevStep={setPrevStep}
          />
        );
      case 3:
        return (
          <Register
            currentStep={demandeStep}
            setCurrentStep={setDemandeStep}
            TYPE={"DEMANDE"}
            setPrevStep={setPrevStep}
          />
        );
      case 4:
        return (
          <DemandeConfirm />
        );
      default:
    }
  };

  return (
    <div className="">
      <Header title={"Saweblia | Demande"}>

        {/* <div className="mt-6">
        <div>
          <div className="flex items-center my-1 sm:ml-5 gap-3">
            {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-btnyellow cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
            }

            <button
              className="bg-[#FDB813] rounded-lg py-1 px-8 ring-0"
              onClick={() => setDemandeStep(prevStep)}
            >
              Retour
            </button>
          </div>
        </div> */}
        {renderDemandeStep()}
        {/* </div> */}
      </Header>
    </div>

  );
}

export default DemandePersonalise

