import axios from "axios"
import Endpoint from "./endpoint"

const createAdress = async (adress) => {
  const result = await axios.post(`${Endpoint}adresses/new`, adress)
  return result.data.data
}

const getAdresseByIdClient = async (idClient) => {
  const result = await axios.get(`${Endpoint}adresses/clients/${idClient}`)
  return result.data.data
}

const updateAddressById = async (id, addresse) => {
  const result = await axios.put(`${Endpoint}adresses/update/${id}`, addresse)
  return result.data.data
}

export {
  createAdress,
  getAdresseByIdClient,
  updateAddressById
}