import axios from "axios"
import Endpoint from "./endpoint"

const createDevis = async (devis) => {
  const result = await axios.post(`${Endpoint}devis/new`, devis)
  return result.data.data
}

const getDevisById = async (idDevis) => {
  const result = await axios.get(`${Endpoint}devis/${idDevis}`)
  return result.data.data
}

const UpdateDevis = async (idDevis, devis) => {
  const result = await axios.put(`${Endpoint}devis/${idDevis}`, devis)
  return result.data.data
}

const getReglement = async (idDevis) => {
  const result = await axios.get(`${Endpoint}reglement/${idDevis}`)
  return result.data.data
}

const getAllDevis = async (idClient) => {
  const result = await axios.get(`${Endpoint}devis/many/${idClient}`)
  return result.data.data
}

export {
  createDevis,
  getDevisById,
  UpdateDevis,
  getReglement,
  getAllDevis,
}