import React, { useContext, useEffect, useState } from 'react';
import Context from '../../context/context'
import { getAllDevis } from "../../services/devis"
import formatNumber from '../../utils/formatPrice';
import moment from 'moment';
import { Link } from 'react-router-dom'
import { UpdateDevis } from '../../services/devis';

const ClientDemandeList = () => {

  const { client } = useContext(Context)
  const [devis, setDevis] = useState([])

  const ConfirmDevis = async (devisElm) => {
    let updatedDevis = await UpdateDevis(devisElm._id, {
      ...devisElm, dateCommande: moment(Date.now()).format(), status: 'Confirmé'
    })
    let updatedDevisList = devis

    console.log();
    setDevis(updatedDevisList.map(d => { return d._id === updatedDevis._id ? updatedDevis : d }))
  }

  const renderButton = (devisElm) => {
    if (devisElm?.status === "En Attente") return (<button onClick={() => ConfirmDevis(devisElm)} className='bg-[#34A853] text-white rounded-md py-1 px-7'>Accepter le devis</button>)
    else if (devisElm?.status === "Confirmé" && !devisElm?.estPaye) return (<a rel="noopener noreferrer" href={`https://microservices.saweblia.ma:3000/tempPayment/${devisElm?._id}`} target="_blank"><button className='bg-[#FDB813] text-white rounded-md py-1 px-5'>Payer la prestation</button></a>)
    else if (devisElm?.estPaye) return (<button className='bg-[#FDB813] text-white rounded-md py-1 px-3'>Evaluer l'intervention</button>)
    else return (<button className=''></button>)
  }

  const fetchDevis = async () => {
    let idClient = JSON.parse(localStorage.getItem("user"))?.idClient._id || JSON.parse(localStorage.getItem("user"))?.idClient
    if (idClient) {
      let fetchedDevis = await getAllDevis(idClient)
      setDevis(fetchedDevis)
    }
  }
  useEffect(() => {
    fetchDevis()
  }, [])

  return (
    <div className='flex flex-col w-full max-w-[58rem] py-4 px-2 container mx-auto'>
      <h4 className="yellowUnderLine relative font-bold text-2xl w-full mb-8" >Bonjour, {JSON.parse(localStorage.getItem("user"))?.idClient?.nom}</h4>
      <p className='my-5 ml-2'>Bienvenue dans votre espace. Ici vous pourrez suivre vos demandes, consulter vos devis et payer vos prestations</p>
      <div className='flex flex-col gap-5 py-6 pl-2 md:pl-6'>
        {
          devis.map((deviselm) => (
            <div className='flex flex-col md:flex-row md:justify-between md:items-center w-full border-b-2 py-2 gap-3' key={deviselm._id}>
              <div className='flex flex-col max-w-lg'>
                <p><span className='font-semibold'>{deviselm?.status === "Confirmé" ? "Commande" : "Devis"} N° {deviselm?.ref}</span> en date du {deviselm?.status === "Confirmé" ? moment(deviselm?.dateCommande).format("DD/MM/YYYY") : moment(deviselm?.creeA).format("DD/MM/YYYY")}</p>
                <ul className='list-disc '>
                  {
                    deviselm?.prestations.map(prestation => (
                      <li className='ml-6' key={prestation._id}>{prestation?.libelle}</li>
                    ))
                  }
                </ul>
                <p>Net à payer : {formatNumber(deviselm?.montantNet)} MAD</p>
              </div>
              <div className='flex'>
                <Link to={`/devis/${deviselm._id}`}>
                  <button className='bg-[#324EA1] text-white rounded-md py-1 px-10 mr-5'>Voir</button></Link>
                {/* {renderButton(deviselm)} */}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default ClientDemandeList;
