import React from 'react';
import { motion } from "framer-motion";

const NavList = ({ step, selectStep }) => {
  return (
    <div className='nav-list flex flex-col items-center'>

      <motion.ul
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {step?.children.map((child, i) => (
          <motion.li
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            key={child.id}
            onClick={() => selectStep(child.id)}
            className="flex flex-col my-4 px-2 md:px-8 cursor-pointer"
          >
            <div className="flex flex-row items-center w-full">
              {/*  <p id={child.id} dangerouslySetInnerHTML={{ __html: child.svg }} className="mr-5 w-30 h-28  hidden sm:block" /> */}
              <img src={child.svg} alt="" className="mr-5 w-28 h-24 hidden sm:block" />
              <div className='w-full'>
                <span className='flex text-xl mb-2 py-1 px-6 bg-[#788CC7] text-white items-center rounded-lg'>{child.libelle}
                  <span className='float-right ml-auto'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                    </svg>
                  </span>
                </span>
                <span className='text-base'>
                  <p className='pl-4'>
                    {child.description}
                  </p>
                </span>
              </div>
            </div>

          </motion.li>
        ))}
      </motion.ul>
    </div>
  );
}


export default NavList;
