const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.8rem"
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
    border: "solid 1px #404040",
    borderRadius: "8px",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#788CC7",
    border: "solid 1px #788CC7",
    borderRadius: "5px",
    color: "white"
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "white",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontSize: "0.75rem"
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  }
}

export default customStyles