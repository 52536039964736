import React, { useContext, useEffect } from 'react'
import { motion } from "framer-motion";
import Context from "../../context/context";

export default function DevisInfo({ stepTitle }) {
  const { setPrevStep } = useContext(Context);

  useEffect(() => {
    setPrevStep(null)
  }, [])
  return (
    <motion.div className='px-2 lg:flex flex-col gap-2 items-center'>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="gradient text-white py-4 px-2 my-2 h-max w-full max-w-lg self-center min-w-max">
        <div className="flex flex-col px-3">
          <h5 className='yellowUnderLine relative mb-6'>Inclus dans la prestation : </h5>
          <ul className='info-list ml-2'>
            <li>Frais de déplacement</li>
            <li>Nettoyage après intervention</li>
            <li>Main d'oeuvre</li>
            <li>Garantie "Satisfait ou refait"</li>
          </ul>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, }}
        className='border-[#14166B] border text-gray-800 rounded-md pl-3 pr-12 py-4'>
        <h3 className='font-bold text-lg StepTitle text-center relative mb-8 pl-4'>Bon à savoir</h3>
        <h5 className='font-bold my-2'>Que se passe-t’il si la durée réelle est différente de la durée estimée ?</h5>
        <span className='break-words'>Le Pro une fois sur place va vérifier si la durée estimée correspond à la réalité de la prestation à effectuer.  La durée pourra être ajustée (+ ou -) sans pour autant dépasser 30 minutes.</span>
        <h5 className='font-bold my-2'>Et si j’ai besoin de fourniture</h5>
        <span>
          Le devis ne comprend pas la fourniture. Si la prestation nécessite une fourniture, elle sera identifiée par le Pro sur place et chiffrée sur place par nos équipes. Le prix vous sera communiqué et votre accord sera attendu pour continuer la prestation
        </span>
        <h5 className='font-bold my-2'>Garantie “Satisfait ou refait” <span className='text-sm'>jusqu’à 6 mois</span> </h5>
        <span>
          Votre prestation est couverte par une garantie qui vous offre la possibilité de voir la prestation refaite si elle n'est pas conforme à ce qui avait été commandé initialement.
        </span>
      </motion.div>
    </motion.div>
  )
}
