import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Devis from './pages/devis';
import Home from './pages/home';
import Profil from './pages/profil';
import Identification from "./Components/authFlow/Identification";
import Register from "./Components/authFlow/Register";
import Login from "./Components/authFlow/Login";
import AuthFlow from "./Components/authFlow/index"
import DemandeForm from './pages/demadeConfirme';
import DemandeAccepted from './pages/demandeAccepted';
import Footer from './Layout/Footer';
//<Route path="/DemandePerso" element={<DemandePersonalise />} />

const Router = () => {
  const [ide, setIde] = useState(1)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/identification" element={<Identification />}></Route>
        <Route path="/signUp" element={<Register />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path='/profil' element={<Profil />} />
        <Route path="/devis/:id" element={<Devis />} />
        <Route path="/DemandeAccepted" element={<DemandeAccepted />} />
        <Route path="/demande" element={<DemandeForm />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default Router;
