import React from 'react';
import { Helmet } from "react-helmet";
const Header = ({ title, children }) => {
  return (
    <div className='h-full'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
}

export default Header;
