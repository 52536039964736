import React from 'react';
import Header from '../Layout/Header'
import ClientDevis from '../Components/Client/ClientDevis';
import NavBar from '../Layout/NavBar';

const Devis = () => {
  return (
    <div className=''>
      <Header title={"Saweblia | Devis"}>
        <NavBar />
        <ClientDevis />
      </Header>
    </div>
  );
}

export default Devis;
