import React,{memo} from "react";
import { motion } from "framer-motion";
import { ReactComponent as SVG } from "../../Assets/svg/google.svg";

const ButtonGoogle = () => {
  const handlegoogleLogin = () => {
    const width = 600,
      height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    // const url = `${API_URL}/${provider}?socketId=${socket.id}`

    window.open(
      "https://microservices.saweblia.ma:15000/auth/google",
      "google_auth",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
          scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
          height=${height}, top=${top}, left=${left}`
    );
  };
  return (
    <motion.button
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="mt-6 w-fit"
      onClick={handlegoogleLogin}
    >
      <motion.div
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-white px-11 py-2 text-center rounded-3xl my-2 flex gap-1 font-medium text-lg items-center justify-center bg-[#283995]"
        //onClick={()=>{setCurrentStep(5)}}
      >
        <SVG className="mr-1" />
        Continuez avec Google
      </motion.div>
    </motion.button>
  );
};

export default memo(ButtonGoogle);
