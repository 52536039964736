import AnswerList from "./AnswerList";
import InputNumberList from "./InputNumberList";

const QuestionAnswer = ({ stepName, children }) => {
  const renderChild = (component, stepName, children) => {
    switch (component) {
      case "answer-list":
        return <AnswerList stepName={stepName} children={children} />;
      case "input-number-list":
        return <InputNumberList stepName={stepName} children={children} />;
      default:
    }
  };
  return (
    <div>
      <h2>{stepName}</h2>

      {children.map((child, i) => (
        <div key={child.id}>
          <h3>{child.question}</h3>
          {renderChild(child.component, child.stepName, child.children)}
        </div>
      ))}
    </div>
  );
};

export default QuestionAnswer;
