import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import DevisInfo from '../Devis/DevisInfo';
import { ReactComponent as Phone } from '../../Assets/svg/phone.svg';
import modePaiement from '../../Constants/modePaiement';
import { getDevisById, UpdateDevis, getReglement } from '../../services/devis';
import { getDemandeById, updateDemandeById } from '../../services/demande';
import { getAdresseByIdClient, createAdress } from '../../services/addresse';
import { getClientById } from "../../services/client"
import formatNumber from '../../utils/formatPrice';
import timeConvert from '../../utils/timeConvert';
import moment from 'moment'
import quartier from '../../Constants/quartier';
import _ from "lodash"
import axios from 'axios';

const ClientDevis = () => {
  const params = useParams();

  const [devis, setDevis] = useState(null)
  const [client, setClient] = useState(null)
  const [demande, setDemande] = useState(null)
  const [adresses, setAdresses] = useState([])
  const [newAddresse, setNewAddresse] = useState(null)
  const [isNewAddress, setIsNewAddress] = useState(false)
  const [reglements, setReglements] = useState([])
  const [devisAddress, setDevisAddress] = useState(null)
  const [checkedCGU, setCheckedCGU] = useState(false)
  const [checkedOffers, setCheckedOffers] = useState(false)
  const [refused, setrefused] = useState(false)
  const [reason, setReason] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const fetchDevis = await getDevisById(params.id);
      setDevis(fetchDevis)
      const fetchClient = await getClientById(fetchDevis.idClient)
      setClient(fetchClient)
      const fetchReglements = await getReglement(params.id);
      setReglements(fetchReglements)
      const fetchAdresses = await getAdresseByIdClient(fetchDevis.idClient)
      setAdresses(fetchAdresses)
      const fetchDemande = await getDemandeById(fetchDevis.idDemande)
      setDemande(fetchDemande)
      setDevisAddress(_.find(adresses, ["_id", fetchDevis?.idAdresse]))
    }
    fetchData();
  }, [])

  const handleDemandeDisponibiliteChange = async (event) => {
    if (devis?.status !== "En attente") return
    let disponibilite = { ...demande?.disponibilite, [event.target.name]: event.target.value }
    console.log(disponibilite);
    let updatedDemande = await updateDemandeById(demande._id, { ...demande, disponibilite })
    setDemande(updatedDemande)
  }

  const handlePaiementChange = async (event) => {
    if (devis?.estPaye) return
    let updatedDevis = await UpdateDevis(devis._id, { ...devis, modePaiement: event.target.value })
    setDevis(updatedDevis)
  }

  const HandleAddresseChange = async (event) => {
    if (devis?.status !== "En attente") return
    let updatedDevis = await UpdateDevis(devis._id, { ...devis, idAdresse: event.target.value })
    setDevis(updatedDevis)
  }

  const CommandeDevis = async () => {
    let updatedDevis = await UpdateDevis(devis._id, { ...devis, dateCommande: moment(Date.now()).format(), status: 'Confirmé' })
    setDevis(updatedDevis)
  }

  const HandleInputsAddressChange = (event) => {
    setNewAddresse({ ...newAddresse, [event.target.name]: event.target.value })
  }
  const AddAddresse = () => {
    if (devis?.status !== "En attente") return
    setIsNewAddress(true)
  }
  const CreateAddresse = async () => {
    let Addresse = { ...newAddresse, idClient: devis?.idClient, isPrincipal: adresses.length === 0 ? true : false }
    let createdAddresse = await createAdress(Addresse)
    let updatedDevis = await UpdateDevis(devis._id, { ...devis, idAdresse: createdAddresse._id })
    setDevis(updatedDevis)
    const fetchAdresses = await getAdresseByIdClient(updatedDevis.idClient)
    setAdresses(fetchAdresses)
    setIsNewAddress(false)
  }
  const GetCurrentPossition = () => {
    navigator.geolocation.getCurrentPosition(
      (pos) => { console.log(pos.coords); setNewAddresse({ ...newAddresse, latitude: pos.coords.latitude, longitude: pos.coords.longitude }) },
      (err) => {
        console.log(err.code, err.message);
        if (err.code === err.PERMISSION_DENIED)
          console.log("you denied me :-(");
      },
      { timeout: 5000, enableHighAccuracy: true, maximumAge: 0 }
    );
  }

  const [isCollapsedInfo, setIsCollapsedInfo] = useState(false)
  const [isCollapsedInclus, setIsCollapsedInclus] = useState(false)

  useEffect(() => {
    let btn = document.querySelector('#collapseInclus')

    var ob = new MutationObserver(function (event) {

      (event[0]?.target?.className?.includes("collapse show") || event[0]?.target?.className?.includes("collapsing")) ? setIsCollapsedInclus(true) : setIsCollapsedInclus(false)
    });
    ob.observe(btn, {
      attributes: true,
      attributeFilter: ["class"]
    });
  })

  useEffect(() => {
    let btn = document.querySelector('#collapseInfo')

    var ob = new MutationObserver(function (event) {
      (event[0]?.target?.className?.includes("collapse show") || event[0]?.target?.className?.includes("collapsing")) ? setIsCollapsedInfo(true) : setIsCollapsedInfo(false)
    });
    ob.observe(btn, {
      attributes: true,
      attributeFilter: ["class"]
    });
  })

  const generatePaymentLink = async () => {
    if (devis?.montantRestant) {
      const response = await axios.post(`https://microservices.saweblia.ma/generateOnlinePayment/${devis?._id}`, { montant: devis?.montantRestant })
      console.log(response.data)
      response && window.open(response.data.paymentUrl, '_blank')
    }
  }

  const refuseDevis = async () => {
    if (reason) {
      let updatedDevis = await UpdateDevis(devis._id, { ...devis, raisonRefus: reason, status: "Refusé" })
      console.log(updatedDevis);
      setDevis(updatedDevis)
    }
  }

  return (
    <div className='flex flex-col w-full max-w-[58rem] px-2 container mx-auto items-center justify-center py-4 text-xl'>
      <h4 className="yellowUnderLine relative font-bold text-xl md:text-2xl w-full" >Bonjour, {client?.nom}</h4>
      <div className="px-2 md:px-16 underlined relative font-bold text-xl md:text-2xl mt-12 mb-4 mx-auto text-center md:w-96">
        <h3>Votre devis N°{devis?.ref}</h3>
        <h6 className="font-normal text-textgray-default/70 text-base md:text-lg">
          {devis?.status === "Confirmé"
            ? moment(devis?.dateCommande).format("DD/MM/YYYY")
            : moment(devis?.creeA).format("DD/MM/YYYY")}
        </h6>
      </div>
      <p className="m-5 text-base md:text-lg">Nous vous remercions d’avoir fait appel à Saweblia pour vos travaux. Vous trouverez ci-dessous les informations relatives à votre devis.</p>
      <div className='mt-2 pl-3 w-full flex flex-col items-center lg:items-start lg:flex-row gap-1 md:gap-14 lg:gap-20 lg:justify-between justify-center'>

        <div className="p-4 flex flex-col items-center text-black lg:flex w-full lg:w-[90vw] gap-3">
          {/* <div className="px-16 text-gray-800 underlined relative mb-4 mx-auto font-medium text-center">
            <h3 className='w-fit relative text-center self-center text-2xl font-bold'>
              Prestations à réaliser par le Pro
            </h3>
          </div> */}

          <h3 className="font-semibold text-lg md:text-xl self-start text-textgray-dark/80 underlinedYellow relative">
            Détail de l’intervention
          </h3>

          {devis?.prestations?.length > 0 && (
            <ul className="prestation-list my-2 w-full">
              {devis?.prestations?.map((pres) => (
                <div
                  className="accordion-item border-b border-gray-500 border-dashed rounded-none bg-white"
                  key={pres?._id}
                >
                  <h2
                    className="accordion-header mb-0"
                    id={`flush-heading${pres?._id}`}
                  >
                    <button
                      className="accordion-button collapsed border-0 relative flex items-center w-full py-2 text-lg md:text-xl text-textgray-default font-semibold text-left bg-whit rounded-none transition focus:outline-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${pres?._id}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapse${pres?._id}`}
                    >
                      {pres?.libelle}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${pres?._id}`}
                    className="accordion-collapse border-0 collapse"
                    aria-labelledby={`flush-heading${pres?._id}`}
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body py-2 px-2 text-base md:text-lg">
                      {pres?.description}
                    </div>
                  </div>
                </div>
              ))}
            </ul>
          )}

          <div className="flex flex-col gap-3 text-textgray-dark/80 w-full">
            <div className="w-full">
              <div className="flex flex-row justify-between gap-2">
                <span className="text-lg md:text-xl">
                  Durée{" "}
                </span>

                <span className="font-bold text-lg md:text-xl">
                  {_.find(devis?.prestations, function (p) {
                    return p?.presForfaitaire;
                  })
                    ? _.find(devis?.prestations, function (p) {
                      return p?.presForfaitaire;
                    }).presForfaitaireDesc
                    : timeConvert(
                      devis?.prestations?.reduce(
                        (acc, obj) =>
                          obj?.quantite ? acc + obj?.quantite : acc,
                        0
                      )
                    )}
                </span>
              </div>
              <p className="text-textgray-dark/80 text-xs">
                Durée estimée des travaux
              </p>
            </div>

            <div>
              <div className="flex flex-row justify-between gap-2">
                <span className="max-w-sm">
                  <p className="text-lg md:text-xl">Tarif</p>
                </span>
                <span className="font-bold text-lg md:text-xl">
                  {formatNumber(
                    devis?.details?.totalPrestationsHT +
                    devis?.details?.margePrestations
                  )}{" "}
                  Dhs
                </span>
              </div>
              <p className="text-textgray-dark/80 text-xs">
                Toutes taxes comprises - hors fourniture ou matériel de
                remplacement
              </p>
            </div>

            {devis?.fournitures.length > 0 && (
              <div>
                <div className="flex flex-row justify-between gap-2">
                  <span className="max-w-xs text-lg md:text-xl">
                    Fournitures & accessoires
                  </span>
                  <span className="font-bold">
                    {formatNumber(
                      devis?.details?.totalFournituresHT +
                      devis?.details?.margeFournitures
                    )}{" "}
                    Dhs
                  </span>
                </div>
                <ul className='list-disc ml-7 mb-1'>
                  {
                    devis?.fournitures.map(fourniture => (
                      <li className='text-sm' key={fourniture._id}>{fourniture?.libelle}</li>
                    ))
                  }
                </ul>
                <p className="text-textgray-dark/80 text-xs">
                  Toutes taxes comprises - livrés et garantis par Saweblia
                </p>
              </div>
            )}

            {devis?.montantPromotionRemise > 0 && (
              <div className="flex flex-row justify-between gap-2">
                <span className="text-lg md:text-xl">
                  Code MYPROMO
                  <p className="text-textgray-dark/80 text-xs">
                    {/* Valable jusqu'au 6 juin 2022 */}
                  </p>
                </span>
                <span className="font-bold">
                  - {formatNumber(devis?.montantPromotionRemise)} Dhs
                </span>
              </div>
            )}

            <hr />

            <div className="flex flex-row justify-between gap-2 text-textgray-darkest font-semibold text-lg md:text-xl">
              <span>Total à payer</span>
              <span className="font-bold">
                {formatNumber(devis?.montantNet)} Dhs
              </span>
            </div>

            {devis?.status === "Confirmé" && (
              <>
                {
                  reglements.length > 0 &&
                  reglements.map(reglement => (
                    <div className='flex flex-row justify-between gap-2 my-2'>
                      <div>
                        <span>Paiement reçu</span>
                        <p className="text-textgray-dark/80 text-xs">
                          le{" "}
                          {reglement?.creeA &&
                            moment(
                              reglement?.creeA,
                              "YYYY-MM-DD HH:mm Z"
                            ).format("DD/MM/YYYY")}
                        </p>
                      </div>
                      <div>
                        <span className="font-bold">
                          {formatNumber(reglement?.montantTotal)} Dhs
                        </span>
                      </div>
                    </div>
                  ))}

                {
                  <div className='flex flex-row justify-between gap-2 text-textgray-dark/90 font-semibold text-lg md:text-xl my-2'>
                    <span>Reste à payer</span>
                    <span className="font-bold">
                      {formatNumber(devis?.montantRestant)} Dhs
                    </span>
                  </div>
                }
              </>
            )}
            <div>
              <button
                className="w-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseInclus"
                aria-expanded="false"
                aria-controls="collapseInclus"
              >
                <div className="flex justify-between mb-4 items-center w-full">
                  <h3 className='font-semibold text-lg md:text-xl text-textgray-dark/80 underlinedYellow relative pb-1 text-start'>
                    Inclus dans la prestation
                  </h3>

                  <span className="inline-block underline text-blue-600 font-medium text-xs leading-tight">
                    {isCollapsedInclus
                      ? "- Masquer le détail"
                      : "+ Afficher le détail"}
                  </span>
                </div>
              </button>

              <div
                className="collapse text-base"
                id="collapseInclus"
              >
                <ul className="checkList relative ml-2 mb-2 leading-8">
                  <li>Frais de déplacement</li>
                  <li>Assistance client 6/7J</li>
                  <li>Nettoyage après intervention</li>
                  <li>Garantie “Satisfait ou refait” jusqu’à 6 mois</li>
                </ul>
              </div>


              <button className="w-full mb-4 focus:outline-none focus:ring-0 transition duration-150 ease-in-out" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInfo" aria-expanded="false" aria-controls="collapseInfo">
                <div className="flex justify-between items-center w-full">
                  <h3 className='font-semibold text-lg md:text-xl text-textgray-dark/80 underlinedYellow relative'>
                    Bon à savoir
                  </h3>

                  <span className="inline-block underline text-blue-600 font-medium text-xs leading-tight">
                    {isCollapsedInfo
                      ? "- Masquer le détail"
                      : "+ Afficher le détail"}
                  </span>
                </div>
              </button>

              <div
                className="collapse pl-2 text-base"
                id="collapseInfo"
              >
                <h5 className="font-bold my-2">
                  Que se passe-t’il si la durée réelle est différente de la
                  durée estimée ?
                </h5>
                <span className="break-words">
                  Une fois sur place, le Pro analysera la situation et vous
                  pourra ajuster le cas échéant le devis définitif. En cas de
                  non-acceptation du devis, aucun frais n’est engagé (ni
                  déplacement, ni établissement du devis)
                </span>
                <h5 className="font-bold my-2">
                  Et si j’ai besoin de fourniture
                </h5>
                <span>
                  Le devis ne comprend pas la fourniture. Si la prestation
                  nécessite une fourniture, elle sera identifiée par le Pro sur
                  place et chiffrée sur place par nos équipes. Le prix vous sera
                  communiqué et votre accord sera attendu pour continuer la
                  prestation
                </span>
                <h5 className="font-bold my-2">
                  Garantie “Satisfait ou refait” jusqu’à 6 mois
                </h5>
                <span>
                  Votre prestation est couverte par une garantie qui vous offre
                  la possibilité de voir la prestation refaite si elle n'est pas
                  conforme à ce qui avait été commandé initialement.
                </span>
                <h5 className="font-bold my-2">
                  Est-ce qu’il y a un délai pour valider le devis ?
                </h5>
                <span>
                  Tous nos devis sont valables 15 jours pour des raisons de
                  fluctuations de la fourniture et des frais logisitiques.
                </span>
              </div>
            </div>

            {
              devis?.status === "En attente" ?
                <div className='w-full sm:w-4/5'>
                  <div className='flex flex-col'>
                    <span className='text-lg md:text-xl mb-2'>Mode de paiement</span>
                    <select name="modePaiement" onChange={handlePaiementChange} disabled={devis?.status !== "En attente"} className='w-full md:w-96 text-base md:text-lg border border-gray-600 rounded-xl px-3 py-2' value={devis?.modePaiement}>
                      <option hidden>Sélectionner le mode de paiement</option>
                      {
                        modePaiement.map((mode) => (
                          <option key={mode.id} value={mode.name}>{mode.name}</option>
                        ))
                      }
                    </select>
                    <p className='text-sm mb-2 mt-1'>Pour les paiements sur place, le montant sera exigé par le Pro à la fin de la prestation, avant de quitter les lieux.  Pour un paiement en espèces, pourriez-vous prévoir l’appoint :)</p>
                  </div>

                  <div className='flex flex-col'>
                    <span className='text-lg md:text-xl mb-2'>Adresse d'intervention</span>
                    <select name="idAdresse" id="" className='w-full md:w-96 text-base md:text-lg border border-gray-600 rounded-xl px-3 py-2' value={devis?.idAdresse} onChange={HandleAddresseChange} >
                      <option hidden>Sélectionner l’adresse d’intervention</option>
                      {
                        adresses.map((adress) => (
                          <option key={adress?._id} value={adress?._id}>{`${adress?.rue} ${adress?.quartier} ${adress?.ville}`}</option>
                        ))
                      }
                    </select>
                    {devis?.status === "En attente" && <p className='underline cursor-pointer mt-1 mb-3 text-blue-600 text-base' onClick={AddAddresse}>+ Ajouter une adresse d’intervention</p>}

                    {
                      devis?.status === "En attente" && isNewAddress &&
                      <div className='flex flex-col gap-4 text-lg md:text-xl'>
                        <textarea type="text" name="rue" onChange={HandleInputsAddressChange} placeholder='Rue / Résidence / N°' className='w-full border border-gray-600 text-base md:text-lg rounded-md px-3 py-2' />
                        <div className='flex flex-row gap-2 w-full'>
                          <input type="text" name="personneAcontacter" onChange={HandleInputsAddressChange} placeholder='Personne à contacter' className='border border-gray-600 text-base md:text-lg rounded-md px-3 py-2 w-full' />
                          <input type="text" name="personneAcontacterTele" onChange={HandleInputsAddressChange} placeholder='Personne à contacter Tél.' className='border border-gray-600 text-base md:text-lg rounded-md px-3 py-2 w-full' />
                        </div>
                        <div className='flex flex-row gap-2 w-full'>
                          <span className='w-full'>Ville
                            <select name="ville" onChange={HandleInputsAddressChange} className='w-full border border-gray-600 text-base md:text-lg rounded-md px-3 py-2'>
                              <option hidden>Ville</option>
                              <option value="Casablanca">Casablanca</option>
                            </select></span>
                          <span className='w-full'>Quartier
                            <select name="quartier" onChange={HandleInputsAddressChange} className='w-full border border-gray-600 text-base md:text-lg rounded-md px-3 py-2'>
                              <option hidden>Quartier</option>
                              {
                                quartier.map(q => (
                                  <option value={q.id} key={q.id}>{q.name}</option>
                                ))
                              }
                            </select></span>
                        </div>
                        {/* <div className="flex">
                    <span>Latitude
                      <input type="text" name="latitude" className="w-full border border-gray-600 rounded-xl px-3 py-2" value={newAddresse?.latitude} onChange={HandleInputsAddressChange} /></span>
                    <span>Longitude
                      <input type="text" name='longitude' className="w-full border border-gray-600 rounded-xl px-3 py-2" value={newAddresse?.longitude} onChange={HandleInputsAddressChange} /></span>
                  </div> */}
                        <button className=' bg-blue-600 w-fit my-3 px-28 self-center py-1 rounded text-white text-base md:text-lg' onClick={CreateAddresse}>Enregistrer</button>
                      </div>
                    }


                  </div>

                  <div className='flex flex-col gap-2'>
                    <span className='text-lg md:text-xl'>Date / Créneau horaire</span>
                    <div className='flex flex-col sm:flex-row gap-2'>
                      <input type="date" name="date" disabled={devis?.status !== "En attente"} onChange={handleDemandeDisponibiliteChange} className='w-full text-base md:text-lg border border-gray-600 rounded-xl px-3 py-2' value={moment(demande?.disponibilite?.date, "YYYY-MM-DD HH:mm Z").format("YYYY-MM-DD")} />
                      <select name="creneauHoraire" disabled={devis?.status !== "En attente"} onChange={handleDemandeDisponibiliteChange} className='w-full text-base md:text-lg border border-gray-600 rounded-xl px-3 py-2' value={demande?.disponibilite?.creneauHoraire}>
                        {/* <option>Dés que possible</option> */}
                        <option value="" hidden>Créneaux horaire</option>
                        <option value="Matinée">Matinée 9h00 - 12h00</option>
                        <option value="Pause Déj">Pause Déj 12h00 - 14h00</option>
                        <option value="Après-midi">Après-midi 14h00 - 19h00</option>
                      </select>
                    </div>
                  </div>
                </div>

                :
                <>
                  {
                    devisAddress &&
                    <div className='flex flex-col mb-1 text-lg md:text-xl'>
                      <span className='text-lg md:text-xl font-bold'>Adresse d'intervention</span>
                      <span>
                        {devisAddress && `${devisAddress?.rue} ${devisAddress?.quartier} ${devisAddress?.ville}`}
                      </span>
                    </div>
                  }
                  {
                    demande?.disponibilite &&
                    <div className='flex flex-col text-lg md:text-xl md:flex-row gap-2 mb-1 justify-between'>
                      <span className='text-lg md:text-xl font-bold'>Date / Créneau horaire</span>
                      <span>
                        {` ${demande?.disponibilite?.date ? moment(demande?.disponibilite?.date, "YYYY-MM-DD HH:mm Z").format("DD/MM/YYYY") : ""} ${demande?.disponibilite ? ("Entre " + demande?.disponibilite?.starthour + " - " + demande?.disponibilite?.endhour) : ""} `}
                      </span>
                    </div>
                  }

                  {devis?.modePaiement &&
                    <div>
                      <div className='flex flex-col text-lg md:text-xl md:flex-row mb-1 justify-between'>
                        <span className='text-lg md:text-xl font-bold'>Mode de paiement</span>
                        <span>{devis?.modePaiement}</span>
                      </div>
                      <p className='text-sm'>Pour les paiements sur place, le montant sera exigé par le Pro à la fin de la prestation, avant de quitter les lieux.  Pour un paiement en espèces, pourriez-vous prévoir l’appoint :)</p>
                    </div>
                  }

                </>
            }
          </div>


          {
            devis?.status === "En attente" &&
            <div className='w-full my-2'>
              <div
                className='w-full text-sm text-textgray-dark/80'>
                <input type="checkbox" name="" id="" className='mr-2' value={checkedCGU} onChange={() => setCheckedCGU(!checkedCGU)} />J’accepte les <a href='https://www.saweblia.ma/cgu' target="_blank" rel="noopener noreferrer" className='text-blue-400'>conditions générale d’utilisation du services</a>
              </div>
              <div
                className='w-full text-sm text-textgray-dark/80'>
                <input type="checkbox" name="" id="" className='mr-2' value={checkedOffers} onChange={() => setCheckedOffers(!checkedOffers)} />J’accepte de recevoir les offres de SERVICES A DOMICILE et ses partenaires
              </div>
            </div>
          }
          {
            devis?.status === "En attente" &&
            <>
              <button className='uppercase my-3 bg-btngreen px-12 text-base md:text-lg py-1 rounded text-white disabled:opacity-30'
                disabled={!checkedCGU}
                onClick={CommandeDevis}>Je Commande</button>
              <p className='inline-flex items-center underline text-textgray-dark/80 text-sm cursor-pointer' onClick={() => setrefused(true)}>Je refuse la proposition</p>
              {
                refused &&
                <div className='flex flex-col items-center md:flex-row gap-4'>
                  <select name="" id="" className='w-full md:w-96 text-base md:text-lg border border-gray-600 rounded-xl px-3 py-2'
                    onChange={(e) => setReason(e.target.value)}
                  >
                    <option value="Raison du refus" hidden>Raison du refus</option>
                    <option value="Prix élevé">Prix élevé</option>
                    <option value="Je n'ai plus besoin de cette prestation">Je n'ai plus besoin de cette prestation</option>
                    <option value="La prestation ne correspond pas à mon besoin">La prestation ne correspond pas à mon besoin</option>
                    <option value="Le temps d'envoi du devis est trop long">Le temps d'envoi du devis est trop long</option>
                  </select>
                  <button className='uppercase bg-btngreen w-fit px-12 py-1.5 rounded text-base md:text-lg text-white' onClick={refuseDevis}>Envoyer</button>
                </div>
              }


            </>
          }
          {
            devis?.status === "Confirmé" && (
              !devis.estPaye ?
                <button onClick={generatePaymentLink} className='uppercase my-3bg-btngreen px-12 py-1 rounded text-base md:text-lg text-white'>Je paye en ligne</button>
                :
                <a rel="noopener noreferrer" href={`https://youengage.me/p/61aa501e5e4223010002feae`} target="_blank" className='my-3'>
                  <button className='uppercase bg-btngreen px-12 py-1 rounded text-base md:text-lg text-white'>J’évalue la prestation</button>
                </a>
            )

          }
        </div>
      </div>
    </div>
  );
}

export default ClientDevis;
