import React, { useState, useContext, useEffect } from "react";
import Context from "../../context/context";
import { motion } from "framer-motion";
import axios from "axios";
import ENDPOINT from "../../services/endpoint";
import ButtonGoogle from "./GoogleButton";

const Login = ({ currentStep, setCurrentStep,setPrevStep }) => {
  const { client, setClient, clientAuthInfo, setClientAuthInfo, credentials, setCredentials } = useContext(Context);
  const handleCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    setPrevStep(1)
  }, [])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user && user?.googleId && user?.idClient?.tele) {
      console.log("user in login ", user)
      setCurrentStep(1);
    }
  }, [])

  const handleLogIn = async () => {
    try {
      const response = await axios.post(`${ENDPOINT}client/login`, { data: credentials })
      const { User, token } = response.data
      localStorage.setItem('user', JSON.stringify(User));
      localStorage.setItem('token', token);
      console.log("here login")
      User && token && setCurrentStep(1)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='flex flex-col items-center px-2'>
      <h3 className="font-bold text-center text-gray-600">Connecter-vous pour valider votre commande</h3>
      <motion.button
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="w-fit">
        {/* <motion.div 
        initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5  , delay:0.2 }} 
        className="text-white px-11 py-2 text-center rounded-3xl my-4 flex gap-1 font-medium text-lg items-center justify-center bg-[#283995]"
        //onClick={()=>{setCurrentStep(5)}}
        >  
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" className="h-5 w-5 mr-2 fill-white" >
          <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/>
        </svg>
        Continuez avec Google
        </motion.div> */}
        <ButtonGoogle />
        <div className="text-center relative self-center flex items-center gap-3"><div className="underlined relative w-full"></div> ou <div className="underlined relative w-full"></div></div>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="p-2 text-center rounded-xl my-4 flex flex-col gap-1 font-medium text-lg items-center justify-center border border-gray-900"
        //onClick={()=>{ setCurrentStep(5)}}
        >
          <input type="email" name="email" className="w-full text-center focus:ring-0 focus:outline-none" onChange={handleCredentials} placeholder="Adresse email" />
        </motion.div>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="p-2 text-center rounded-xl my-4 flex flex-col gap-1 font-medium text-lg items-center justify-center border border-gray-900"
        // onClick={()=>{ setCurrentStep(5)}}
        >
          <input type="password" name="password" className="w-full text-center focus:ring-0 focus:outline-none" onChange={handleCredentials} placeholder="Mot de passe" />
        </motion.div>

        <motion.button
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="text-white w-full px-11 py-2 text-center rounded-3xl my-4 font-medium text-lg items-center justify-center bg-[#283995]"
          onClick={async () => {
            await handleLogIn()

          }}
        >
          Se connecter
        </motion.button>


      </motion.button>
      <span className="text-xs text-gray-700">Vous n'avez pas de compte utilisateur ?</span>
      <span className="text-xs text-blue-400">Créez un compte en quelques secondes</span>
    </div>
  );
}

export default Login;
