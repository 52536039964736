import React, { useEffect, useState } from 'react';
import { useContext } from "react";
import Context from "../../context/context";
import { motion } from "framer-motion";
import formatNumber from "../../utils/formatPrice";
import timeConvert from "../../utils/timeConvert";
import _ from "lodash"

const DevisRecapitulatif = ({ currentStep, setCurrentStep, path }) => {
  const { prestation, adresse, totalPrice, totalHour, disponibiliteDate, creneauHoraire, inputs, devis, client } = useContext(Context)
  //const { prestation, adresse, totalPrice, totalHour, disponibiliteDate, creneauHoraire, inputs } = useContext(Context)
  const { createCommande } = useContext(Context)
  /*  useEffect(() => {
     path && path.forEach(elm => {
       elm.libelle && console.log("libelle ", elm.libelle);
       elm.question && console.log("question ", elm.question);
       elm.stepName && console.log("stepName ", elm.stepName);
     })
   }, []) */

  const [isCollapsedInfo, setIsCollapsedInfo] = useState(false)
  const [isCollapsedInclus, setIsCollapsedInclus] = useState(false)

  useEffect(() => {
    let btn = document.querySelector('#collapseInclus')

    var ob = new MutationObserver(function (event) {

      (event[0]?.target?.className?.includes("collapse show") || event[0]?.target?.className?.includes("collapsing")) ? setIsCollapsedInclus(true) : setIsCollapsedInclus(false)
    });
    ob.observe(btn, {
      attributes: true,
      attributeFilter: ["class"]
    });
  })

  useEffect(() => {
    let btn = document.querySelector('#collapseInfo')

    var ob = new MutationObserver(function (event) {
      (event[0]?.target?.className?.includes("collapse show") || event[0]?.target?.className?.includes("collapsing")) ? setIsCollapsedInfo(true) : setIsCollapsedInfo(false)
    });
    ob.observe(btn, {
      attributes: true,
      attributeFilter: ["class"]
    });
  })

  useEffect(() => {
    window.scroll({
      top: 90,
      left: 0,
      behavior: 'smooth'
    });
  }, [])

  useEffect(() => {

    console.log("racu", _.find(prestation.map(p => p.prestation), function (p) { return p?.presForfaitaire }) ? _.find(prestation.map(p => p.prestation), function (p) { return p?.presForfaitaire }).presForfaitaireDesc : timeConvert(totalHour))

  }, [])

  useEffect(() => {

    /* var header = document.getElementById("ConfirmBtn");
    var sticky = header.offsetTop;

    const stickyBtn = () => {

      let body = document.body;
      let html = document.documentElement;
      let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      console.log(window.pageYOffset, height, header.offsetTop, header.clientHeight, header.clientTop);

      if (window.pageYOffset < header.clientHeight) {
        header.classList.add("sticky"); //bg-btnblue-600/20
      } else {
        header.classList.remove("sticky");
        header.classList.remove("bg-btnblue-600/20");
        console.log("object");
      }

    }
    window.onscroll = function () { stickyBtn() }; */


  })


  return (
    <div className='w-full md:w-[45rem] px-2'>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="py-1 flex flex-col lg:flex w-full gap-1 md:gap-3">

        {/* <motion.h3
          initial={{ y: 10 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
          className='underlinedPrestation w-fit relative text-center mb-6'>
            Prestations à réaliser par le Pro</motion.h3> */}

        {/* <motion.ul className='prestation-list text-white self-start mb-4 my-2'>
          {
            prestation.map((pres) => (
              <motion.li key={pres.id}>{pres?.prestation?.libelle} {/* <span className='text-green-400 font-semibold'> x {pres.unite}</span> *//*}
              </motion.li>
            ))
          }
        </motion.ul> */}

        <h3 className='font-semibold text-xl text-textgray-dark/80 w-fit underlinedYellow relative mt-1'>
          Détail de l’intervention
        </h3>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="accordion accordion-flush my-2" id="accordionFlushExample">
          {
            prestation.map((pres) => (
              <div className="accordion-item border-b border-gray-500 border-dashed rounded-none bg-white" key={pres?.prestation?._id}>
                <h2 className="accordion-header mb-0" id={`flush-heading${pres?.prestation?._id}`}>
                  <button className="accordion-button collapsed border-0 relative flex items-center w-full py-2 text-xl text-textgray-default font-semibold text-left bg-whit rounded-none transition focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${pres?.prestation?._id}`}
                    aria-expanded="false" aria-controls={`flush-collapse${pres?.prestation?._id}`}>
                    {pres?.prestation?.libelle}
                  </button>
                </h2>
                <div id={`flush-collapse${pres?.prestation?._id}`} className="accordion-collapse border-0 collapse"
                  aria-labelledby={`flush-heading${pres?.prestation?._id}`} data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body py-2 px-2 text-lg">
                    {pres?.prestation?.description}
                  </div>
                </div>
              </div>
            ))
          }

        </motion.div>



        <div className='flex flex-col'>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="flex flex-row justify-between gap-1 sm:gap-2">
            <span className='text-textgray-dark/80 flex flex-col'>Durée </span>
            <span className='font-bold pl-px'>{_.find(prestation.map(p => p.prestation), function (p) { return p?.presForfaitaire }) ? _.find(prestation.map(p => p.prestation), function (p) { return p?.presForfaitaire }).presForfaitaireDesc : timeConvert(totalHour)}</span>
          </motion.div>
          <span className='text-sm text-textgray-dark/80 font-thin'>Durée estimé des travaux</span>
        </div>
        <div className='flex flex-col'>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="flex flex-row justify-between gap-1 sm:gap-2">
            <span className='text-textgray-dark/80'>Tarif
            </span>
            <span className='font-bold pl-px'>{formatNumber(totalPrice)} Dhs</span>
          </motion.div>
          <div
            className='flex flex-col mt-0'>
            <span className='text-sm text-textgray-dark/80 font-thin'>Toutes taxes comprises - hors fourniture ou matériel de remplacement</span>
            <span className='text-sm text-textgray-dark/80 font-thin'>Inclus frais de déplacement 50 Dhs toutes taxes comprises</span>
          </div>
        </div>



        {
          disponibiliteDate && creneauHoraire &&
          <>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="flex flex-col sm:flex-row justify-between gap-x-1 sm:gap-x-2">
              <span className='text-textgray-dark/80'>{/*  Intervention souhaitée {typeof (disponibiliteDate) !== "string" && "le"} */}
                Date d'intervention
              </span>
              {typeof (disponibiliteDate) !== "string" ?
                <span className='font-bold pl-px'>{disponibiliteDate?.toLocaleDateString("fr-FR", { month: 'long', day: 'numeric', year: "numeric" })}</span>
                :
                <span className='font-bold pl-px'>{disponibiliteDate}</span>
              }

            </motion.div>

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="flex flex-col sm:flex-row justify-between gap-x-1 sm:gap-x-2">
              <span className='text-textgray-dark/80'>Créneau de passage </span>
              <span className='font-bold pl-px'>{creneauHoraire}</span>
            </motion.div>
          </>
        }
        {
          localStorage.getItem("user") &&
          <>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="flex flex-col sm:flex-row justify-between gap-x-1 sm:gap-x-2">
              <span className='text-textgray-dark/80'>Nom / Prénom</span>
              <span className='font-bold pl-px'>{JSON.parse(localStorage.getItem("user"))?.idClient?.nom}</span>
            </motion.div>

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="flex flex-col sm:flex-row justify-between gap-x-1 sm:gap-x-2">
              <span className='text-textgray-dark/80'>Tél.</span>
              <span className='font-bold pl-px'>{JSON.parse(localStorage.getItem("user"))?.idClient?.tele}</span>
            </motion.div>
          </>
        }
        {
          adresse?.addresse && adresse?.quartier && adresse?.ville &&
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="flex flex-col text-black w-full">
            <div className='flex flex-col'>
              <div className="flex flex-col gap-2">
                <span className='text-textgray-dark/80'>L’intervention aura lieu à : </span>
              </div>

              <div className="flex flex-col gap-2">
                <span className='font-bold'> {/*"Rue de la Paix, 9éme étage Appart 69 - 5éme étage rue de la paix  - Bourgogne, Casablanca"*/}
                  {`${adresse.addresse} - ${adresse.quartier}  ,  ${adresse.ville}`}
                </span>
              </div>
            </div>
          </motion.div>
        }
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >

          <button className="w-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInclus" aria-expanded="false" aria-controls="collapseInclus">
            <div className="flex justify-between w-full">
              <h3 className='font-semibold text-xl mb-3 text-textgray-dark/80 text-start pb-1 underlinedYellow relative'>
                Inclus dans la prestation
              </h3>

              <span className='inline-block underline text-blue-600 font-medium text-xs leading-tight'>{isCollapsedInclus ? "- Masquer le détail" : "+ Afficher le détail"}</span>
            </div>
          </button>

          <div className="collapse" id="collapseInclus">
            <ul className='checkList relative ml-1  text-base leading-8'>
              <li>Frais de déplacement</li>
              <li>Assistance client 6/7J</li>
              <li>Nettoyage après intervention</li>
              <li>Garantie “Satisfait ou refait” jusqu’à 6 mois</li>
            </ul>
          </div>
        </motion.div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >

          <button className="w-full focus:outline-none mb-3 focus:ring-0 transition duration-150 ease-in-out" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInfo" aria-expanded="false" aria-controls="collapseInfo">
            <div className="flex justify-between  items-center w-full">
              <h3 className='font-semibold text-xl text-textgray-dark/80 underlinedYellow relative'>
                Bon à savoir
              </h3>

              <span className='inline-block underline text-blue-600 font-medium text-xs leading-tight'>{isCollapsedInfo ? "- Masquer le détail" : "+ Afficher le détail"}</span>
            </div>
          </button>

          <div className="collapse pl-2 text-base" id="collapseInfo">
            <h5 className='font-bold my-2'>Que se passe-t’il si la durée réelle est différente de la durée estimée ?</h5>
            <span className='break-words'>Une fois sur place, le Pro analysera la situation et vous pourra ajuster le cas échéant le devis définitif. En cas de non-acceptation du devis, aucun frais n’est engagé (ni déplacement, ni établissement du devis)</span>
            <h5 className='font-bold my-2'>Et si j’ai besoin de fourniture</h5>
            <span>
              Le devis ne comprend pas la fourniture. Si la prestation nécessite une fourniture, elle sera identifiée par le Pro sur place et chiffrée sur place par nos équipes. Le prix vous sera communiqué et votre accord sera attendu pour continuer la prestation
            </span>
            <h5 className='font-bold my-2'>Garantie “Satisfait ou refait” jusqu’à 6 mois</h5>
            <span>
              Votre prestation est couverte par une garantie qui vous offre la possibilité de voir la prestation refaite si elle n'est pas conforme à ce qui avait été commandé initialement.
            </span>
            <h5 className='font-bold my-2'>Est-ce qu’il y a un délai pour valider le devis ?</h5>
            <span>
              Tous nos devis sont valables 15 jours pour des raisons de fluctuations de la fourniture et des frais logisitiques.
            </span>
          </div>
        </motion.div>

        <div className='-mx-2 backdrop-blur-sm bottom-0 left-0 sticky flex justify-center right-0' id="ConfirmBtn">
          <motion.button
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className='bg-btnblue-500 disabled:bg-btnblue-300/50 rounded-2xl py-2 px-10 my-8 self-center text-white'
            onClick={() => { setCurrentStep(10); createCommande(path) }}>
            Confirmer
          </motion.button>
        </div>

      </motion.div>

    </div >
  );
}

export default DevisRecapitulatif;
