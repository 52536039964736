import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import ENDPOINT from "../../services/endpoint";
import Context from "../../context/context";
import { motion } from "framer-motion";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";


const Register = ({ currentStep, setCurrentStep }) => {
  const [isShowForm, setIsShowForm] = useState(false);
  const { client, setClient, clientAuthInfo, setClientAuthInfo, setPrevStep } = useContext(Context);
  const [credentials, setCredentials] = useState({});
  const [error, setError] = useState("");
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  // const [Token , setToken] = useState("")

let TokenVar;
  const clearState = () => {
    setClient({})
    setClientAuthInfo({})
  }

  // useEffect(()=>{
  //   setClient({...client,Token})

  // },[Token])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    if ((clientAuthInfo?.googleId && client?.tele) || (user && user?.idClient?.tele)) {
      // localStorage.setItem('user', JSON.stringify(client));
      // localStorage.setItem('token',token);
      setPrevStep(3)
      setCurrentStep(4)

    } else {
      setPrevStep(4)

    }
  }, [])


  const handleRegister = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value});
    console.log(client);
  };

  const handleEnregistrer = async () => {
    try {
      console.log(`${ENDPOINT}clients/register`);
      const response = await axios.post(`${ENDPOINT}clients/register`, {
        data: {...client,TokenVar},
      });
      console.log(`response`,response);
      localStorage.setItem('user', JSON.stringify(response.data.data));
      response && setClient(client)
      response && setCurrentStep(4)
      
    } catch (error) {
      console.log(error);
    }
  };

  const handleCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleCancel = () => {
    localStorage.clear()
    setClient(null)
    setClientAuthInfo(null)
    setCurrentStep(4)

  }
 
  function getElementByXpath(path) {
    //return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    var iframe = document.getElementsByTagName("iframe")[0];
    console.log(iframe)
    // var elmnt = iframe.contentWindow.document.getElementsByTagName("H1")[0];
    // elmnt.style.display = "none";
    }

  return (
       <div className="flex flex-col items-center w-[70vw] gap-3 md:w-[40vw]">
        <h3 className="font-bold text-center relative text-gray-600">
          Pour continuer, nous avons besoin de vos informations personnelles
        </h3>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="mt-6 w-full flex"
        >
          <select
            name=""
            id=""
            className="mt-1 rounded-lg py-2.5 px-3 border border-gray-400 mr-2"
          >
            <option value="M">M.</option>
            <option value="Mme">Mme</option>
            <option value="Mlle">Mlle</option>
          </select>
          <input
            type="text"
            placeholder="Prénom et nom"
            name="nom"
            value={client?.nom}
            onChange={handleRegister}
            className="mt-1 w-full rounded-lg py-2 px-3 border border-gray-400"
          />
        </motion.div>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="w-full"
        >
          <input
            value={client?.tele}
            name="tele"
            type="tel"
            onChange={handleRegister}
            placeholder="Tél"
            className="mt-1 w-full rounded-lg py-2 px-3 border border-gray-400"
          />
        </motion.div>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="w-full"
        >
          <input
            value={client?.email}
            name="email"
            type="email"
            onChange={handleRegister}
            placeholder="Email"
            className="mt-1 w-full rounded-lg py-2 px-3 border border-gray-400"
          />
        </motion.div>
        {!clientAuthInfo?.googleId && (
          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="w-full"
          >
            <input
              name="password"
              type="password"
              onChange={handleRegister}
              placeholder="Mot de passe"
              className="mt-1 w-full rounded-lg py-2 px-3 border border-gray-400"
            />
          </motion.div>
        )}
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="w-full"
        >
          <select
            name="via"
            onChange={handleRegister}
            className="mt-1 w-full rounded-lg py-2 px-3 border border-gray-400"
          >
            <option value="" hidden>
              Comment avez-vous connu Saweblia ?
            </option>
            <option value="Google/Facebook/Instagram">
              Google/Facebook/Instagram
            </option>
            <option value="Partenaire">
              Partenaire (Brico Déco, Bricoma, Biougnach ...)
            </option>
            <option value="Recommandation">
              Recommandation (Ami, Famille ...)
            </option>
            <option value="Je ne sais plus">Je ne sais plus</option>
          </select>
        </motion.div>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="w-full text-xs text-gray-500"
        >
          <input type="checkbox" name="" id="" className="mr-2" />
          Je souhaite être informé des nouveautés, cadeaux et bons plans
        </motion.div>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="w-full text-xs text-gray-500"
        >
          <input type="checkbox" name="" id="" className="mr-2" />
          Je souhaite recevoir les bons plans des partenaires
        </motion.div>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="w-full text-xs text-gray-500"
        >
          En vous inscrivant vous acceptez{" "}
          <span className="text-blue-400">les conditions générales</span> et la{" "}
          <span className="text-blue-400">politique de confidentialité</span>
        </motion.div>
        

        <div className="flex flex-row gap-2 justify-center items-center w-full">
          {clientAuthInfo?.googleId && (
            <motion.button
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              onClick={handleCancel}
              transition={{ duration: 0.5, delay: 0.7 }}
              className="text-white lg:w-64 w-44 py-2 text-center rounded-3xl mt-2 font-medium text-lg items-center justify-center bg-[#283995]"
            >
              {" "}
              Annuler
            </motion.button>
          )}

          <motion.button
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            onClick={handleEnregistrer}
            disabled={
              !client?.nom ||
              !client?.email ||
              !client?.tele ||
              !(client?.password || clientAuthInfo?.googleId) ||
              !client?.via
            }
            transition={{ duration: 0.5, delay: 0.7 }}
            className="text-white lg:w-64 w-44 py-2 text-center rounded-3xl mt-2 font-medium text-lg items-center justify-center bg-btnblue-500 disabled:bg-btnblue-300/50"
          >
            {clientAuthInfo?.googleId ? `Confirmer` : `S\'enregistrer`}
          </motion.button>
          <GoogleReCaptcha
            onVerify={(token) => {
              TokenVar = token
             
              console.log(token , TokenVar)
              //getElementByXpath()
              // const frame = getElementByXpath(
              //   "//iframe[@title='reCAPTCHA'][0]"
              // );
              //  console.log(frame)
              //setToken(token)
            }}
          />
        </div>
      </div> 
  );


};

export default Register;
