import React, { useState, useContext, useEffect ,useMemo, useCallback , memo} from "react";
import Context from "../../context/context";
import { motion } from "framer-motion";
import ENDPOINT from "../../services/endpoint"
import { io } from "socket.io-client";
import axios from "axios"
import ButtonGoogle from "./GoogleButton"
import { v1 as uuid } from 'uuid';
let socket

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const Identification =({ currentStep, setCurrentStep ,TYPE ,setPrevStep ,path}) => {
  const { browserId ,client, setClient, clientAuthInfo, setClientAuthInfo,setBrowserId ,postDemande,count,setCount,postDemandePerso} = useContext(Context);
  const idBrowser = browserId || uuid()
  const postIdBrowser = async () => {
    
    if (!browserId) {
      setBrowserId(idBrowser)
      const response = await axios.post(`${ENDPOINT}setIdBrowser`, { idBrowser: idBrowser || browserId })
    }
  }
  useEffect(() => {
    postIdBrowser()
  }, [])

  useEffect(() => {
    const connectionOptions = {
      forceNew: true,
      reconnectionAttempts: "Infinity",
      timeout: 10000,
      transports: ["websocket"],
    };
    socket = io(ENDPOINT, connectionOptions);

    console.log(`${idBrowser}`)
    if(idBrowser) {
      socket.on(`${idBrowser}`, (data) => {
        let { client, user } = data
        user.idClient = client
        console.log(data)
        user && setClientAuthInfo(user)
        client && setClient(client)
        user && localStorage.setItem('user', JSON.stringify(user));
        user && client && client?.tele && setCurrentStep(3);
      }) 
    }
  }, [])
  useEffect(()=>{
     verifyToken()
  },[client])

  useEffect(() => {
    setPrevStep(TYPE === "DEMANDE" ? 5 :null)
  }, [])
  useCallback(()=>{
    console.log(count)
  },[])

  useEffect(() => {
      const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token') || null
    if (user && user?.googleId && user?.idClient?.tele) {
      console.log("googleId")
      setClientAuthInfo({
        googleId: user?.googleId,
        nom: user.nom,
      })
      setClient(user?.idClient)
      if(TYPE === "DEMANDE"){
        console.log(client,"client")
        postDemandePerso(path,user?.idClient)
      }
      setCurrentStep(4);
    }
    if (user && user?.password && user?.idClient?.tele) {
      console.log("password")
      setClientAuthInfo({
        password: user?.password,
        email: user?.email,
      })
      setClient(user?.idClient)
      if(TYPE === "DEMANDE"){
        console.log(client,"client",count)
        postDemandePerso(path,user?.idClient)
      }
      setCurrentStep(4);
    }
    if (user && user?.googleId && !user?.idClient?.tele) {
      console.log("user google auth 2 ", user)
      setClientAuthInfo({
        googleId: user?.googleId
      })
      setClient(user?.idClient)
      setCurrentStep(3);
    }
    if (token && !user?.googleId) {
      console.log("user google auth 3 ", user)
      verifyToken()
     
    }
  }, [])

  const verifyToken = async () => {
    const token = localStorage.getItem('token');
    try {
      const res = await axios.post(`${ENDPOINT}verifyToken`, { token: token });
      const { data } = res;
      console.log(data.user.idClient)
      setClient(data.user.idClient)
      if (data.user.idClient?.tele) {
        setCurrentStep(4)
      } else {
        setCurrentStep(3)
      }
    } catch (error) {
      console.log(error)
    }
  }



  // //   useEffect(()=>{
  // //     clearState()
  // // },[])
  //     const handleRegister = (e) => {
  //       setClient({ ...client, [e.target.name]: e.target.value });
  //       console.log(client);
  //     };

  //     const handleEnregistrer = async () => {
  //       try {
  //         console.log(`${ENDPOINT}clients/register`);
  //         const response = await axios.post(`${ENDPOINT}clients/register`, {
  //           data: client,
  //         });
  //         console.log(response);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     };

  //     const handleCredentials = (e) => {
  //       setCredentials({ ...credentials, [e.target.name]: e.target.value });
  //     };

  //     const handleLogin = async () => {
  //       try {
  //         console.log(`${ENDPOINT}client/login`);
  //         // const response = await axios.post(`${ENDPOINT}client/login`, {
  //         //   client:{
  //         //       name,email,tele
  //         //   },
  //         //   clientsAuth:{
  //         //     name,email,tele,password
  //         // }
  //         // });
  //         console.log(response);
  //       } catch (error) {
  //         console.log(error);
  //         setError(error);
  //       }
  //     };
  //     const googleFailure = (err) => {
  //       console.log(err);
  //     };
  //     const googleSucces = async (res) => {
  //       console.log(res);
  //       const {name , email , googleId , imageUrl , accessToken , tokenId } = res?.profileObj || res;
  //       const token = res?.tokenId;
  //       setClient({name,email,imageUrl })
  //       setClientAuthInfo({ googleId , email, name, accessToken , tokenId})
  //       setLoginStatus(true);

  //       //setUser(result);
  //       //localStorage.setItem("profile", JSON.stringify({ result ,token}));
  //       //console.log(result);

  //     //   try {
  //     //     const response = await axios.post(`${ENDPOINT}/clients/register`,
  //     //     {
  //     //         client:{
  //     //             name,email
  //     //         },
  //     //         clientsAuth:{
  //     //             googleId,accesToken,email
  //     //         }
  //     //     })

  //     //   } catch (err) {
  //     //     console.log(err);
  //     //   }
  //     };

  //     const logout = () => {
  //       console.log("logout");
  //       localStorage.clear()
  //       setClient({ })
  //       setClientAuthInfo({ })
  //       setLoginStatus(false);
  //     };

  return (
    <div className="flex flex-col items-center gap-3 px-2">
      <h3 className="font-bold text-center underlined text-gray-600 relative">
        Pour continuer, nous avons besoin de vos informations personnelles
      </h3>
      <motion.ul
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="mt-6 w-fit"
      >
        <motion.li
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-white cursor-pointer py-2 text-center rounded-3xl my-4 font-medium text-lg items-center justify-center bg-[#283995]"
          //onClick={()=>{ setCurrentStep(5)}}
          onClick={() => {
            setCurrentStep(2);
          }}
        >
          Déja inscrit ? Connectez-vous
        </motion.li>
        <motion.li
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="underlined relative my-6"
        ></motion.li>
        {
          <motion.li
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="text-white cursor-pointer py-2 text-center rounded-3xl mb-1 mt-12 font-medium text-lg items-center justify-center bg-[#788CC7]"
            onClick={() => {
              setCurrentStep(3);
            }}
          >
            Créez un compte
          </motion.li>
        }

        <ButtonGoogle />
        {/* <button onClick={handlegoogleLogin}>connectez avec google</button> */}


        {/* {clientAuthInfo?.googleId ? (
          <GoogleLogout
            clientId="484344463797-2kf5nbfp5tbm2d0aiqlom0gtoesrlp1q.apps.googleusercontent.com"
            buttonText="Logout"
            // onSuccess={googleSucces}
            //onFailure={(res)=>console.log(res)}
            onLogoutSuccess={logout}
            //cookiePolicy={"single_host_origin"}
          ></GoogleLogout>
        ) : (
          <GoogleLogin
            onSuccess={googleSucces}
            onFailure={googleFailure}
            isSignedIn={false}
            // autoLoad={false}
            //cookiePolicy="single_host_origin"
            clientId="484344463797-2kf5nbfp5tbm2d0aiqlom0gtoesrlp1q.apps.googleusercontent.com"
            render={(renderProps) => {
              return (
                <button
                  className="text-white px-11 py-2 text-center rounded-3xl flex gap-1 my-4 font-medium text-lg items-center justify-center bg-[#283995]"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  google
                </button>
              );
            }}
          />
        )} */}

        {/* <motion.li 
        initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5  , delay:0.6 }} 
        className="text-white px-11 py-2 text-center rounded-3xl flex gap-1 my-4 font-medium text-lg items-center justify-center bg-[#283995]"
        onClick={()=>{setCurrentStep(5)}}
        >  
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" className="h-5 w-5 mr-2 fill-white" >
          <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/>
        </svg>
        Continuez avec Google
        </motion.li> */}
      </motion.ul>
    </div>
  );
}

export default memo(Identification);
