import DepannageReparation from "./DepannageReparation";
import depannageSvg from '../Assets/svg/depannageSvg.svg';
import petitTravauxSvg from '../Assets/svg/petitTravauxSvg.svg';
import travauxRenovationSvg from '../Assets/svg/travauxRenovationSvg.svg';
import PetitsTravaux from '../Extract/Petits travaux.json'

export default {
  stepNumber: "-1",
  stepName: "Services",
  id: "root-step",
  component: "nav-list",
  children: [
    {
      id: "cfe2f1ba-9c63-4c18-93b7-38d7e8fac9dc",
      libelle: "Dépannage et réparation",
      stepName: "",
      description: "Un problème de plomberie, d'électricité, de serrurerie… ? Une fuite d’eau, un chauffe-eau à remplacer... Pour toutes les prestations de dépannage ... ",
      step: "",
      svg: depannageSvg,
      component: "famille-list",
      children: DepannageReparation
    },
    {
      libelle: "Petits travaux",
      description: "Des envies de bricolage ou des petits travaux d’embellissement de vos espaces intérieurs et extérieurs (peinture, pose de carrelage, nouvelle installation électrique, parquet ou stores vénitiens ...)",
      id: "43c5ca09-67e8-4120-a9b1-e6c08ddbd9b5",
      svg: petitTravauxSvg,
      step: "",
      component: "famille-list",
      children: [
        ...PetitsTravaux,
        {
          question: "J’ai besoin de plusieurs prestations",
          id: "4cde0b23-8c40-4894-8287-418afd661ede",
          stepNumber: "0",
          demandepersonalise: true,
          component: "demande-personalise",
        },
      ]
    },
    {
      libelle: "Travaux de rénovation",
      description: "Envie de rénover votre salle de bain ? Une rénovation complète ou partielle de vos espaces (remplacer une baignoire par une douche, rénover un espace commercial ou un espace en extérieur, poser une pergola sur-mesure...)",
      id: "25f9a5df-030c-4706-836b-5f7fd2a93cb7",
      svg: travauxRenovationSvg,
      step: "",
      component: "demande-personalise",
      demandepersonalise: false,
      children: [
        {
          question: "J’ai besoin de plusieurs prestations",
          id: "42fae556-ac19-4d08-9a24-60b1dd0c2833",
          stepNumber: "0",
          demandepersonalise: true,
          component: "demande-personalise",
        },
      ]
    },
  ]
};
