import React from "react";
import DemandeConfirm from "../Components/Devis/DemandeConfirm";
import Header from "../Layout/Header";
import NavBar from "../Layout/NavBar";
const DemandeAccepted = () => {
  return (
    <div className=''>
      <Header title={"Saweblia | Home"}>
        <NavBar />
        <DemandeConfirm />
      </Header>
    </div>
  );
}

export default DemandeAccepted;