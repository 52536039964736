import React, { useContext, useState, useEffect } from 'react';
import { motion } from "framer-motion";
import Context from "../../context/context";
import _ from 'lodash'
import { getAdresseByIdClient, createAdress } from '../../services/addresse';
import quartier from '../../Constants/quartier';

const DevisAddress = ({ currentStep, setCurrentStep }) => {

  const { client, adresse, setAdresse, setPrevStep, clientAuthInfo } = useContext(Context)

  const [entreprise, setEntreprise] = useState(false)
  const [isNewAddress, setIsNewAddress] = useState(false)
  const [adresses, setAdresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [clicked, setClicked] = useState(false)

  const handleAddressChange = (e) => {
    setAdresse({ ...adresse, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    // console.log(user ,user.tele)
    if ((clientAuthInfo?.googleId && client?.tele) || (user || user?.idClient?.tele)) {
      // localStorage.setItem('user', JSON.stringify(client));
      // localStorage.setItem('token',token);
      setPrevStep(3)
    }
  }, [])
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    let idClient = client?._id || user?.idClient?._id
    async function fetchData() {
      const fetchAdresses = await getAdresseByIdClient(idClient)
      console.log(fetchAdresses);
      setAdresses(fetchAdresses)
    }
    fetchData();
  }, [])

  const AddAddresse = () => {
    setIsNewAddress(true)
    setAdresse(null)
    setSelectedAddress(null)
  }

  const handleAddressSelect = (event) => {
    let selectedAddressID = adresses.find(e => e._id === event.target.value)
    setSelectedAddress(selectedAddressID)
    setAdresse(selectedAddressID)
  }

  const handleConfirm = () => {
    console.log(adresse)
    if (!entreprise && adresse && adresse?.rue && adresse?.quartier && adresse?.ville) {
      setCurrentStep(8)
    }
    else if (entreprise && adresse && adresse?.quartier && adresse?.ville && adresse?.raisonsociale && adresse?.ice) {
      setCurrentStep(8)
    }
    else setClicked(true)
  }

  return (
    <div className='flex flex-col items-center px-2 gap-3 w-full lg:w-2/4'>
      <h3 className="font-bold text-center relative text-gray-600">Pourriez-vous préciser le lieu d’intervention ?</h3>
      <div className='flex flex-col gap-2 w-full md:w-4/5 px-2'>
        <span className='text-xl'>Adresse d'intervention</span>
        {
          adresses.length > 0 &&
          <motion.select
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            name="idAdresse" id="" value={adresse?._id || selectedAddress?._id || ""} className='w-full text-lg border border-gray-400 rounded-xl px-3 py-2'
            style={(clicked && !adresse) ? { border: "red solid 1px" } : {}}
            onChange={handleAddressSelect} >
            <option hidden>Sélectionner une adresse d’intervention</option>
            {
              adresses.map((adress) => (
                <option key={adress?._id} value={adress?._id}>{`${adress?.rue} ${adress?.quartier} ${adress?.ville}`}</option>
              ))
            }
          </motion.select>
        }

        {adresses.length > 0 && <p className='underline cursor-pointer my-2 text-md' onClick={AddAddresse}>+ Ajouter une adresse d’intervention</p>}

        {
          (isNewAddress || adresses.length === 0 || (selectedAddress || adresse) ) &&
          <div className='flex flex-col gap-4'>
            <motion.textarea initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }} type="text" name="rue" rows="3" style={(clicked && !adresse?.rue) ? { border: "red solid 1px" } : {}}
              value={adresse?.rue || ""} onChange={handleAddressChange} placeholder='Adresse (numéro, rue, étage ...)'
              className='w-full border border-gray-400 text-lg rounded-md px-3 py-2' />
            <motion.div
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className='flex flex-row gap-2 w-full'>
              <span className='w-full'>Ville
                <select name="ville" onChange={handleAddressChange} style={(clicked && !adresse?.ville) ? { border: "red solid 1px" } : {}}
                  value={_.upperFirst(adresse?.ville) || ""} className='w-full border border-gray-400 text-lg rounded-md px-3 py-2'>
                  <option hidden>Ville</option>
                  <option value="Casablanca">Casablanca</option>
                  {/* <option value="Rabat">Rabat</option> */}
                  {/* <option value="Mohammedia">Mohammedia</option> */}
                  <option value="Marrakech">Marrakech</option>
                </select></span>
              <span className='w-full'>Quartier
                <select name="quartier" onChange={handleAddressChange} style={(clicked && !adresse?.quartier) ? { border: "red solid 1px" } : {}}
                  value={adresse?.quartier || ""} className='w-full border border-gray-400 text-lg rounded-md px-3 py-2'>
                  <option hidden>Quartier</option>
                  {
                    quartier.map(q => (
                      <option value={q.name} key={q.id}>{q.name}</option>
                    ))
                  }
                </select>
              </span>
            </motion.div>
            <motion.div
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className='flex flex-row gap-2 w-full'>
              <input type="text" name="personneAcontacter" value={adresse?.personneAcontacter || ""} onChange={handleAddressChange} placeholder='Personne à contacter' className='border border-gray-400 text-lg rounded-md px-3 py-2 w-full' />
              <input type="text" name="personneAcontacterTele" value={adresse?.personneAcontacterTele || ""} onChange={handleAddressChange} placeholder='Personne à contacter Tél.' className='border border-gray-400 text-lg rounded-md px-3 py-2 w-full' />
            </motion.div>
            {/* <div className="flex">
                    <span>Latitude
                      <input type="text" name="latitude" className="w-full border border-gray-400 rounded-xl px-3 py-2" value={newAddresse?.latitude} onChange={HandleInputsAddressChange} /></span>
                    <span>Longitude
                      <input type="text" name='longitude' className="w-full border border-gray-400 rounded-xl px-3 py-2" value={newAddresse?.longitude} onChange={HandleInputsAddressChange} /></span>
                  </div> */}
            {/* <button className=' bg-blue-600 w-fit my-3 px-28 self-center py-1 rounded text-white' onClick={CreateAddresse}>Enregistrer</button> */}
          </div>
        }

        {/*  <motion.div initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.1 }} className="mt-6 w-full">
        <textarea name="addresse" value={adresse?.addresse} rows="3" className="w-full mt-1 rounded-lg py-2.5 px-3 border border-gray-400 mr-2" placeholder='Adresse (numéro, rue, étage ...)'
          onChange={handleAddressChange}
        ></textarea>
      </motion.div> */}
        {/* <motion.div initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }} className="w-full flex">
        <select name="ville" value={_.upperFirst(adresse?.ville)} className="mt-1 rounded-lg py-2.5 px-3 border border-gray-400 mr-2 w-full" onChange={handleAddressChange}>
          <option value="" hidden>Ville</option>
          <option value="Casablanca">Casablanca</option>
          <option value="Rabat">Rabat</option>
          <option value="Mohammedia">Mohammedia</option>
          <option value="Marrakech">Marrakech</option>
        </select>
        <select name="quartier" value={adresse?.quartier} className="mt-1 rounded-lg py-2.5 px-3 border border-gray-400 mr-2 w-full" onChange={handleAddressChange}>
          <option value="" hidden>Quartier</option>
          <option value="Bouskoura">Bouskoura</option>
          <option value="Sidi Maarouf">Sidi Maarouf</option>
        </select>

      </motion.div> */}
        {/*  <motion.div
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className='w-full'>
        <input type="text" placeholder='Personne à contacter sur place' name="nameAcontacter" className="mt-1 w-full rounded-lg py-2 px-3 border border-gray-400" onChange={handleAddressChange} />
      </motion.div> */}
        <motion.div initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className='w-full text-sm text-gray-500'>
          <input type="checkbox" name="" id="" className='mr-2' onChange={(e) => setEntreprise(e.target.checked)} />Je demande cette intervention pour une entreprise
        </motion.div>

        {
          entreprise &&
          <>
            <motion.div initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }} className='w-full'>
              <input type="text" name='raisonsociale' placeholder='Raison sociale' style={(clicked && entreprise && !adresse?.raisonsociale) ? { border: "red solid 1px" } : {}}
                className="mt-1 w-full rounded-lg py-2 px-3 border border-gray-400" onChange={handleAddressChange} /></motion.div>
            <motion.div initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }} className='w-full'><input type="text" name='ice' placeholder='ICE' style={(clicked && entreprise && !adresse?.ice) ? { border: "red solid 1px" } : {}}
                className="mt-1 w-full rounded-lg py-2 px-3 border border-gray-400" onChange={handleAddressChange} /></motion.div>
          </>
        }
      </div>


      <motion.button initial={{ x: 20, opacity: 0 }}
        //disabled={entreprise ? !(entreprise && adresse?.quartier && adresse?.ville && adresse?.raisonsociale && adresse?.ice) : !(adresse?.quartier && adresse?.ville && !entreprise)}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.7 }}
        className="text-white px-11 py-2 text-center rounded-3xl my-4 font-medium text-lg items-center justify-center bg-btnblue-500 disabled:bg-btnblue-300/50"
        onClick={handleConfirm}
      >Continuer</motion.button>
    </div>
  );
}

export default DevisAddress;
