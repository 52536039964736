import { motion } from "framer-motion";
import { useContext, useEffect } from "react";
import Context from "../../context/context"
import { sortOptions } from "../../utils/sortOptions";

const List = ({ step, selectStep }) => {
  const { setselectedFamille } = useContext(Context)

  useEffect(() => {
    window.scroll({
      top: 110,
      left: 0,
      behavior: 'smooth'
    });
  }, [])

  return (
    <div className="flex flex-col items-center px-2 md:px-10 xl:px-32">

      <h4 className="StepTitle relative text-center font-medium md:font-bold text-2xl w-full" >{step.libelle}</h4>
      <motion.ul
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="flex flex-wrap items-center gap-x-4 gap-y-1 my-7 px-2"
      >
        {[...sortOptions(step?.children?.filter(e => e.question !== "J’ai besoin de plusieurs prestations"), "question"), ...step?.children?.filter(e => e.question === "J’ai besoin de plusieurs prestations")].map((child, i) => (
          <motion.li
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            key={child.id}
            onClick={() => { selectStep(child.id); setselectedFamille(child.question) }}
            className={`${child?.demandepersonalise ? "bg-[#788CC7]" : "bg-[#14166B]"} 
            mt-4 w-full h-fit sm:h-20 md:w-60 md:h-28 py-2 md:py-5 px-2 flex items-center justify-center text-white rounded-2xl font-medium text-center cursor-pointer`}
          >
            {child.question}
          </motion.li>
        ))}
      </motion.ul>
    </div>
  );
};

export default List;