
import React, { useState, useContext, useEffect } from "react";
import Context from "../../context/context";
import { motion } from "framer-motion";


const DevisTime = ({ currentStep, setCurrentStep }) => {

  const { setPrevStep, setCreneauHoraire } = useContext(Context)
  useEffect(() => {
    setPrevStep(null)
  }, [])
  return (
    <div className='flex flex-col items-center gap-3 px-2 w-full lg:w-2/5'>
      <h3 className="font-bold text-center underlined text-gray-600 relative">Quel créneau horaire vous convient le mieux ?</h3>
      <motion.ul
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="mt-6 w-fit">
        <motion.li
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-white px-11 py-1 text-center rounded-2xl my-4 flex flex-col gap-1 font-medium cursor-pointer bg-btnblue-600"
          onClick={() => { setCreneauHoraire("Matinée"); setCurrentStep(4) }}
        >
          <span>Matinée</span>
          <span>9h00 - 12h00</span>
        </motion.li>

        <motion.li
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="text-white px-11 py-1 text-center rounded-2xl my-4 flex flex-col gap-1 font-medium cursor-pointer bg-btnblue-300"
          onClick={() => { setCreneauHoraire("Pause Déj"); setCurrentStep(4) }}
        >
          <span>Pause Déj</span>
          <span>12h00 - 14h00</span>
        </motion.li>

        <motion.li
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="text-white px-11 py-1 text-center rounded-2xl my-4 flex flex-col gap-1 font-medium cursor-pointer bg-btnblue-600"
          onClick={() => { setCreneauHoraire("Après-midi"); setCurrentStep(4) }}
        >
          <span>Après-midi</span>
          <span>14h00 - 19h00</span>
        </motion.li>
      </motion.ul>
    </div>
  )
}
export default DevisTime