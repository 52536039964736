import { sortOptions } from "../utils/sortOptions";

const quartier = [{
  "name": "Habous",
  "id": "6288bb9c1e3d7a4e72aae473"
}, {
  "name": "Bouskoura",
  "id": "628b809f09c568d47e1535c5"
}, {
  "name": "Hopitaux",
  "id": "628b877409c568d47e153709"
}, {
  "name": "Tamaris",
  "id": "628b8db409c568d47e153899"
}, {
  "name": "Maarif",
  "id": "628ba00309c568d47e1543a5"
}, {
  "name": "Derb Ghalef",
  "id": "628bc47da73f32775921fe0d"
}, {
  "name": "Mohammedia",
  "id": "628bc47da73f32775921fe0e"
}, {
  "name": "Centre ville",
  "id": "628bc47da73f32775921fe0f"
}, {
  "name": "Hay Al Inara",
  "id": "628bc47da73f32775921fe10"
}, {
  "name": "Anassi",
  "id": "628bc47da73f32775921fe11"
}, {
  "name": "Derb Omar",
  "id": "628bc47da73f32775921fe12"
}, {
  "name": "Sidi Bernoussi",
  "id": "628bc47da73f32775921fe13"
}, {
  "name": "Errahama",
  "id": "628bc47da73f32775921fe14"
}, {
  "name": "Bouskoura",
  "id": "628bc47da73f32775921fe15"
}, {
  "name": "La gironde",
  "id": "628bc47da73f32775921fe16"
}, {
  "name": "Lamkansa",
  "id": "628bc47da73f32775921fe17"
}, {
  "name": "Beauséjour",
  "id": "628bc47da73f32775921fe18"
}, {
  "name": "Maarif ancien",
  "id": "628bc47da73f32775921fe19"
}, {
  "name": "Riviera",
  "id": "628bc47da73f32775921fe1a"
}, {
  "name": "Anfa supérieur",
  "id": "628bc47da73f32775921fe1b"
}, {
  "name": "Derb Milan",
  "id": "628bc47da73f32775921fe1c"
}, {
  "name": "Sidi Rahal",
  "id": "628bc47da73f32775921fe1d"
}, {
  "name": "2 Mars",
  "id": "628bc47ea73f32775921fe1e"
}, {
  "name": "El Hana",
  "id": "628bc47ea73f32775921fe1f"
}, {
  "name": "C.I.L.",
  "id": "628bc47ea73f32775921fe20"
}, {
  "name": "Belvédère",
  "id": "628bc47ea73f32775921fe21"
}, {
  "name": "El Hank",
  "id": "628bc47ea73f32775921fe22"
}, {
  "name": "Nassim II",
  "id": "628bc47ea73f32775921fe23"
}, {
  "name": "Quartier Miamar",
  "id": "628bc47ea73f32775921fe24"
}, {
  "name": "Ferme Bretonne",
  "id": "628bc47ea73f32775921fe25"
}, {
  "name": "Anfa",
  "id": "628bc47ea73f32775921fe26"
}, {
  "name": "Roches Noires",
  "id": "628bc47ea73f32775921fe27"
}, {
  "name": "Ancienne médina",
  "id": "628bc47ea73f32775921fe28"
}, {
  "name": "Aïn Diab",
  "id": "628bc47ea73f32775921fe29"
}, {
  "name": "Ain Chock",
  "id": "628d0b589bb56b44eca0a967"
}, {
  "name": "Dar Bouazza",
  "id": "628ddf719bb56b44eca0af8f"
}, {
  "name": "Bourgogne",
  "id": "628e63049bb56b44eca0cb0a"
}, {
  "name": "Ain Diab",
  "id": "628e6d0c9bb56b44eca0cd9e"
}, {
  "name": "Harhoura",
  "id": "62910beaf4be630589e63de6"
}, {
  "name": "Ghandi",
  "id": "629490e3f4be630589e64a7f"
}, {
  "name": "Val Fleuri",
  "id": "62949140f4be630589e64d7b"
}, {
  "name": "yacoub elmanssour",
  "id": "6294a75af4be630589e652c1"
}, {
  "name": "CFC",
  "id": "6294e34df4be630589e65bb1"
}, {
  "name": "Ziraoui",
  "id": "6294e7b4f4be630589e65d8a"
}, {
  "name": "Gauthier",
  "id": "62950958f4be630589e663a8"
}, {
  "name": "Sidi Maarouf",
  "id": "629639eef4be630589e67459"
}, {
  "name": "Gauthier",
  "id": "62973866f4be630589e67a0a"
}, {
  "name": "Hassan 2",
  "id": "6297796ef4be630589e6813c"
}, {
  "name": "Palmier",
  "id": "6298c34ff4be630589e69956"
}, {
  "name": "Nassim",
  "id": "6298cbb8f4be630589e69b27"
}, {
  "name": "Californie",
  "id": "629e0981a5751df797ffb109"
}, {
  "name": "Nearshor",
  "id": "62a0dd0ba5751df797001257"
}, {
  "name": "Médina",
  "id": "62a2128da5751df797002bd2"
}, {
  "name": "Lissasfa",
  "id": "62a228eca5751df797003025"
}, {
  "name": "Racine",
  "id": "62a33888a5751df797003db9"
}, {
  "name": "Oasis",
  "id": "62a6eddfa5751df7970060d0"
}, {
  "name": "Casablanca",
  "id": "62a8a68ea5751df797009747"
}, {
  "name": "Almaz",
  "id": "62b9b3aef64920a0b7de343c"
}, {
  "name": "Moussa ibnou noussair",
  "id": "62b9cb89f64920a0b7de3b79"
}];
export default sortOptions(quartier, "name")