import React , {useContext , useEffect} from 'react';
import Context from "../../context/context";

const DemandeConfirm = () => {
  const {setPrevStep, client}= useContext(Context);
  useEffect(()=>{
    setPrevStep(null)
},[])

  return (
    <div className='flex flex-col items-center text-xl leading-9 text-center'>
      <h1 className="text-2xl font-bold StepTitle relative text-center my-12">Votre demande a été prise en compte</h1>
      <p>Merci  <span className='font-bold'>{client?.nom}</span> </p>
      <p>Nous vous remercions de votre confiance.</p>
      <p className='my-4'>Vous êtes contacté <span className='font-bold'>dans les minutes qui suivent</span> par notre équipe</p>
      <div className='flex flex-col items-center mt-12 text-base'>
        <h3 className='text-[#283995] font-bold StepTitle relative mb-8'>Besoin d'aide ?</h3>
        <p className='text-[#283995]'>En cas de questions ou de problèmes, n’hésitez pas à contacter notre Service Client +212-677330404</p>
        <p className='text-[#283995]'>Du Lun au Sam de 9h à 19h</p>
      </div>
    </div>
  );
}

export default DemandeConfirm;
