import { motion } from "framer-motion";
import { useContext } from "react";
import Context from "../../context/context"
import { sortAnswers } from "../../utils/sortAnswers";

const AnswerList = ({ step, selectStep }) => {

  const { famille } = useContext(Context)

  return (
    <div className="flex flex-col items-center px-2">
      {famille && <h4 className="StepTitle relative text-center font-medium md:font-semibold w-full text-2xl" >{famille}</h4>}
      <div className="w-80 sm:w-96 text-textgray-dark/80 text-xl underlined relative mt-12 mb-4 mx-auto font-semibold text-center">
        <h3>{step?.stepName}</h3>
      </div>

      <motion.ul
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="mt-3 w-full"
      >
        {step?.children?.map((child, i) => (
          <motion.li
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            key={child.id}
            onClick={() => selectStep(child.id)}
            className="text-white px-2 sm:px-6 md:px-20 lg:px-28 py-1 text-center rounded-xl my-4 cursor-pointer even:bg-btnblue-600/80 odd:bg-btnblue-600"
          >{child.question}</motion.li>
        ))}
      </motion.ul>
    </div>
  );
};

export default AnswerList;
