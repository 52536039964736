export default function timeConvert(n) {
  if (!n) return ""
  let num = n
  let num2 = n + 0.5
  let hours = (num / 1);
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);

  let hours2 = (num2 / 1);
  let rhours2 = Math.floor(hours2);
  let minutes2 = (hours2 - rhours2) * 60;
  let rminutes2 = Math.round(minutes2);

  let stringMinute = rminutes < 10 ? "0" + rminutes : rminutes
  let stringHours = rhours ? rhours + "h" : ""

  let stringMinute2 = rminutes2 < 10 ? "0" + rminutes2 : rminutes2
  let stringHours2 = rhours2 ? rhours2 + "h" : ""


  return `Entre ${stringHours}${stringMinute} et ${stringHours2}${stringMinute2}`
  // num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
}