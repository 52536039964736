import axios from "axios"
import Endpoint from "./endpoint"

const createDemande = async (demande) => {
  console.log("demandeService",demande)
  const result = await axios.post(`${Endpoint}demandes/new`, demande)
  return result.data.data
}

const getDemandeById = async (idDemande) => {
  const result = await axios.get(`${Endpoint}demandes/${idDemande}`)
  return result.data.data
}

const updateDemandeById = async (idDemande, demande) => {
  const result = await axios.put(`${Endpoint}demandes/${idDemande}`, demande)
  return result.data.data
}

export {
  createDemande,
  getDemandeById,
  updateDemandeById
}