import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Provider from "./context/provider";
import App from "./App";
import Register from "./Components/authentication/Register";
import Login from "./Components/authentication/Login";
import 'tw-elements';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Identification from "./Components/authentication/Identification";

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Router from "./Router";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey="6LfuADshAAAAAOyTNXJ1q6C-Q8HAAXtAceFG8cBe">
    <Provider>
      <Router />
    </Provider>
    </GoogleReCaptchaProvider>   
  </StrictMode>,
   document.getElementById('gCaptcha')
);
