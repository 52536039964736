import InputNumber from "./InputNumber";
import { useState, useContext, useEffect } from "react";
import Context from "../../context/context"
import _ from "lodash"
import { motion } from "framer-motion";
import { getPrestationsById } from "../../services/prestation"
import { sortOptions } from "../../utils/sortOptions";

/* function formatNumber(num) {
  var number = Number(num).toFixed(2);
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}
function timeConvert(n) {
  var num = n;
  var hours = num / 1;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours === 0
    ? rminutes + "min"
    : rhours + "h " + (rminutes === 0 ? "" : rminutes + "min");
  // num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
} */

const InputNumberList = ({ step, selectStep }) => {

  const { setInputs, famille, setShowDevis, setPrestation, setPrice, setTotalHour } = useContext(Context)

  const [value, setValue] = useState([])
  const [calDurationPrice, setCalDurationPrice] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalDuration, setTotalDuration] = useState(0)
  const [prestations, setPrestations] = useState([])
  const [question, setQuestion] = useState(false)
  const [newValue, setNewValue] = useState([])
  let filtredValue = []

  const inputHandleChange = (e, child) => {
    //console.log("child", child)


    // value.length > 0 ?  value.map(v => 

    //   { console.log(v.id, child.id)
    //     if(v.id === child.id){
    //       setValue(prevState => prevState.map(prev => prev.id === child.id ? {...prev, value:e.target.value} : console.log("prev",prev)))
    //     }else{
    //       setValue([...value, {value:e.target.value,...child}])
    //     }
    //   }) :  setValue([ {value:e.target.value,...child}])
    if (question) return
    if (value.length > 0) {
      _.find(value, { "id": child.id }) ? setValue(prevState => prevState.map(prev => prev.id === child.id ? { ...prev, value: e } : prev)) : setValue([...value, { value: e, ...child }])
    } else {
      setValue([{ value: e, ...child }])
    }

    setInputs(value)
    // console.log("inputs", inputs, value)
  }

  const calcPriceDuration = () => {
    let durations = []
    let TotalDuration = 0
    let TotalPrice = 0
    let tva = 0

    filtredValue.map((v) => {
      console.log("quantite", v?.prestation?.quantite, step?.prestation?.quantite)
      const Duration = v?.prestation?.quantite || step?.prestation?.quantite
      const Coef = v?.prestation?.Coefficient || step?.prestation?.Coefficient
      const vduration = v.value === 0 ? 0 : Duration
      let calDuration = vduration + ((v.value - 1) * (vduration * Coef))
      const prixVente = v?.prestation?.prixAchat / 0.7 // taux de marc
      v.prestation.prixVente = prixVente
      v.prestation.nbrPres = v.value
      let calPrice
      calPrice = calDuration * prixVente
      console.log("calDuration :", calDuration, calPrice, totalPrice, tva)
      TotalDuration = TotalDuration + calDuration
      TotalPrice = TotalPrice + calPrice
      tva = tva + ((prixVente * calDuration - v?.prestation?.prixAchat * calDuration) * 0.2)
      durations.push({ ...v, calDuration, calPrice: calPrice })
    })
    setCalDurationPrice(durations)
    setTotalPrice(TotalPrice + 50 + 3 + tva) // need TVA , 3 pour la calcul du tva pour les fees
    setTotalDuration(TotalDuration)
  }

  const filterPrestations = () => {
    let filtredPrestations = []
    filtredPrestations = _.uniqBy(calDurationPrice.filter(e => e.value >= 1), "prestation[libelle]")
    filtredPrestations.forEach(element => {
      element.quantite = calDurationPrice.reduce((acc, obj) => (obj?.prestation?.libelle === element?.prestation?.libelle ? acc + obj.calDuration : acc), 0);
    });

    return filtredPrestations
  }

  const GenerateDevis = (child) => {
    if (typeof child.prestation === "object") {
      const calDuration = child?.prestation?.quantite + ((child?.prestation?.quantite - 1) * child?.prestation?.Coefficient)
      const prixVente = child?.prestation?.prixAchat / 0.7 // taux de marc
      console.log("prix achat", child?.prestation?.prixAchat)
      let value = [{
        ...child,
        unite: 1,
        value: 1,
        marge: 30,
        nbrPres: 1,
        prixVente: prixVente,
        quantite:calDuration,
        calDuration: calDuration
      }]
      child.prestation.nbrPres = 1;
      child.prestation.marge = 30;
      child.prestation.prixVente = prixVente;
      child.prestation.quantite = calDuration;

      const tva = ((prixVente * calDuration - child?.prestation?.prixAchat * calDuration) * 0.2)
      const price = calDuration * prixVente + tva + 50 + 3
      setShowDevis(true);
      setInputs(value);
      setPrestation(value);
      setPrice(price);
      setTotalHour(child?.prestation?.quantite);
    }
  }

  useEffect(() => {
    const newVal = JSON.parse(JSON.stringify(value));
    filtredValue = _.uniqBy(newVal, "prestation[_id]")
    filtredValue.forEach(element => {
      const data = value.filter(v => v.prestation._id === element.prestation._id).map(v => v.value)
      console.log("element", element.prestation.quantite)
      element.prestation.nbrPres = _.sum(data)
      element.value = _.sum(data)
    });
    //setNewValue(filtredPrestations)
    //console.log("filtredPrestations",filtredPrestations)
    console.log("all values", value)
    console.log("NewValue", filtredValue)

    calcPriceDuration()
  }, [value]);

  useEffect(() => {
    setPrestations(filterPrestations())
  }, [calDurationPrice])

  const fetchPrestation = async () => {
    step?.children.forEach(async child => {
      if (!step.prestation && child.prestation) {
        child.prestation = await getPrestationsById(typeof child?.prestation === "string" ? child?.prestation : child?.prestation?._id)
      }
      else if (step.prestation && !child.prestation) {
        child.prestation = await getPrestationsById(typeof step?.prestation === "string" ? step?.prestation : step?.prestation?._id)
      }
    })
  }
  useEffect(() => {
    console.log(step.children)
    fetchPrestation()
  }, [])

  return (
    <div className="flex flex-col items-center px-2">
      {famille && <h4 className="StepTitle relative text-center font-medium md:font-semibold w-full text-2xl" >{famille}</h4>}
      <div className="sm:w-96 w-80 text-textgray-dark/80 text-xl underlined relative mt-12 mb-4 mx-auto font-semibold text-center">
        <h3>{step.stepName}</h3>
      </div>

      <div className="flex justify-between">
        <div className="my-6 w-fit flex flex-col">
          {sortOptions(step?.children.filter(e => e.hasOwnProperty("name")), "name").map((child) => (
            <>
              {<InputNumber
                onChange={inputHandleChange}
                key={child.id}
                name={child.name}
                min={child.min}
                object={child}
                question={question}
              />}
            </>
          ))
          }
          {
            sortOptions(step?.children.filter(e => e.hasOwnProperty("question")), "question").map((child) => (
              <>
                {
                  (child.question && step?.children?.every(e => e.type !== "radio")) && <motion.div initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    key={child.id}
                    className="my-1 rounded-2xl py-2 cursor-pointer w-full"
                    onClick={() => (!child.children && !child.formulaire) ? GenerateDevis(child) : selectStep(child.id)}
                  >
                    <label className="cursor-pointer w-full">
                      <span className="flex justify-between text-default text-lg font-medium text-textgray-dark/80">
                        {child.question}
                        <span className="ml-5 bg-[#14166b] h-[37px] w-[37px] text-white border rounded-full flex items-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                          </svg>
                        </span>
                      </span>
                    </label>
                  </motion.div>
                }
                {
                  (child.question && step?.children?.every(e => e.type === "radio")) && <motion.div initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    key={child.id}
                    className="my-1 rounded-2xl py-1 px-10 even:bg-btnblue-600/80 odd:bg-btnblue-600 text-white"
                  >
                    <label className="cursor-pointer w-full text-center ">
                      <input type={child.type} className="w-full hidden" value={child.question} name="answer"
                        onChange={() => (!child.children && !child.formulaire) ? GenerateDevis(child) : selectStep(child.id)}
                      />
                      <span className="value flex items-center justify-center">
                        {child.question}
                        {
                          child.type === "checkbox" &&
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 opacity-0 ml-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        }

                      </span>
                    </label>
                  </motion.div>
                }

              </>
            ))
          }
          {/* {
            step?.children.map((child) => (
              <>
                {child.question && child.formulaire && <motion.div initial={{ x: 20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  key={child.id}
                  className="mt-1 py-2 cursor-pointer"
                  onClick={() => selectStep(child.id)}
                >
                  <span className="flex justify-between text-default text-lg font-medium text-textgray-dark/80">
                    {child.question}
                    <span className="ml-5 bg-[#14166b] h-[37px] w-[37px] text-white border rounded-full flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                      </svg>
                    </span>
                  </span>
                </motion.div>}
                {child.question && !child?.formulaire && child.type === "radio" && <motion.div initial={{ x: 20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  key={child.id}
                  className="my-1 rounded-2xl py-1 px-10 even:bg-btnblue-600/80 odd:bg-btnblue-600 text-white"
                >
                  <label className="cursor-pointer w-full text-center ">
                    <input type={child.type} className="w-full hidden" value={child.question} name="answer"
                      onChange={() => GenerateDevis(child)}
                    />
                    <span className="value flex items-center justify-center">
                      {child.question}
                      {
                        child.type === "checkbox" &&
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 opacity-0 ml-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      }

                    </span>
                  </label>

                </motion.div>}
                {child.question && !child?.formulaire && child.type === "checkbox" && <motion.div initial={{ x: 20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  key={child.id}
                  className="my-1 rounded-2xl py-2 cursor-pointer w-full"
                  onClick={() => GenerateDevis(child)}
                >
                  <label className="cursor-pointer w-full text-center">
                    <span className="flex justify-between text-default text-lg font-medium text-textgray-dark/80">
                      {child.question}
                      <span className="ml-5 bg-[#14166b] h-[37px] w-[37px] text-white border rounded-full flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                        </svg>
                      </span>
                    </span>
                  </label>

                </motion.div>}
              </>
            ))
          } */}
        </div>



        {/*
       (value.length > 0  && totalPrice !== "" && totalDuration >0) && <div style={{maxWidth:"750px"}}>
      <h4>{step.prestation}</h4> 
     {
        calDurationPrice.map((v)=>(

          v.calDuration > 0  && <div>
         <p> <span style={{color:"blue" , fontWeight:"bold" , marginRight:"1.5rem"}}>{v.name}</span> 

         <span style={{color:"grey" , fontWeight:"bold" , marginRight:"0.5rem" , marginLeft:"1rem"}}>
         Durée éstimée:
         </span> {timeConvert(v.calDuration)}
         <span style={{color:"grey" , fontWeight:"bold" , marginRight:"0.5rem"  , marginLeft:"1rem"}}>
            Fees:
         </span>{v.fees}
         <span style={{ color:"grey" , fontWeight:"bold" , marginRight:"0.8rem"  , marginLeft:"1rem"}}>
           Prix: 
         </span>{v.calPrice }
          <span style={{ color:"red" , fontWeight:"bold" , marginRight:"0.8rem"  , marginLeft:"1rem"}}>coef : {v.coef} durée : {v.duration}</span> 
         </p>
         </div>

       ))
     }  
          
         <p> <span style={{ fontWeight:"bold" , marginRight:"1.5rem"}}>Durée Total éstimée :</span> {timeConvert(totalDuration)}</p>
          <p> <span style={{fontWeight:"bold" , marginRight:"1.5rem"}}>Prix Total :</span> {totalPrice}</p>
      </div>
      */}
      </div>

      {
        step.children.some(child => child.hasOwnProperty("name")) &&
        <button
          disabled={totalPrice === 0 || totalDuration === 0}
          className="bg-[#324EA1] text-white rounded-xl py-1 px-8 disabled:bg-[#788CC7] disabled:opacity-50 my-4"
          onClick={() => { setShowDevis(true); setInputs(value); setPrestation(prestations); setPrice(totalPrice); setTotalHour(totalDuration) }}
        >Générer mon devis</button>
      }

    </div>
  );
};

export default InputNumberList;
