import React, { useEffect, useState } from 'react';
import Header from '../Layout/Header'
import ClientDemandeList from '../Components/Client/ClientDemandeList';
import NavBar from '../Layout/NavBar';
import Identification from '../Components/authFlow/Identification';
import Login from '../Components/authFlow/Login';
import Register from '../Components/authFlow/Register';
import { useNavigate } from 'react-router-dom';
import Footer from '../Layout/Footer';

const Profil = () => {
  const [profilStep, setProfilStep] = useState(1);
  const [prevStep, setPrevStep] = useState(0);
  const navigate = useNavigate()
  const renderProfilStep = () => {
    let step = profilStep;
    switch (step) {
      case 0:
        return <div />;
      case 1:
        return (
          <Identification
            currentStep={profilStep}
            setCurrentStep={setProfilStep}
            TYPE={"profil"}
            setPrevStep={setPrevStep}
          />
        );
      case 2:
        return (
          <Login
            currentStep={profilStep}
            setCurrentStep={setProfilStep}
            TYPE={"profil"}
            setPrevStep={setPrevStep}
          />
        );
      case 3:
        return (
          <Register
            currentStep={profilStep}
            setCurrentStep={setProfilStep}
            TYPE={"profil"}
            setPrevStep={setPrevStep}
          />
        );
      case 4:
        return (
          <ClientDemandeList
            currentStep={profilStep}
            setCurrentStep={setProfilStep}
            TYPE={"profil"}
            setPrevStep={setPrevStep}
          />
        );
      default:
    }
  };

  useEffect(() => {
    console.log(profilStep);
    renderProfilStep();
  }, [profilStep]);

  return (
    <div className="">
      <Header title={"Saweblia | Profile"}>
        <NavBar />
        <div className="mt-6">
          <div>
            {/*  <div className="flex items-center my-1 sm:ml-5 gap-3">
              {
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-btnyellow cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
              }

              <button
                className="bg-[#FDB813] rounded-lg py-1 px-8 ring-0"
                onClick={() => {prevStep ? setProfilStep(prevStep) : location.replace("https://saweblia.ma/") }}
              >
                Retour
              </button>
            </div> */}
          </div>
          {renderProfilStep()}
        </div>
      </Header>
    </div>
  );
};

export default Profil;