
import React, { useState, useContext, useEffect } from 'react';

import { motion } from "framer-motion";
import Context from "../../context/context";

const DevisPaiement = ({ currentStep, setCurrentStep, path }) => {

  const { setModePaiement, setCodePromo, modePaiement, setPrevStep } = useContext(Context)
  const [condition, setCondition] = useState({})
  const [msg, setMsg] = useState(false)

  const handleConditionChange = (e) => {
    console.log(e.target.checked);
    setCondition({ ...condition, [e.target.name]: e.target.checked })
  }
  const handleCodePromoChange = (e) => {
    setCodePromo(e.target.value)
  }
  const handlePaiementChange = (e) => {
    setModePaiement(e.target.value)
  }

  useEffect(() => {
    setPrevStep(null)
  }, [])

  const handleConfirm = () => {
    if (modePaiement && condition.condition) setCurrentStep(9)
    else setMsg(true)
  }

  return (
    <div className='flex flex-col px-3 gap-3 w-full md:w-4/5 lg:w-1/2'>
      {/*     <motion.h3 initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.1 }}
        className="font-bold relative text-gray-600">Disposez-vous d’un code promo ou bon d’achat ?</motion.h3>
      <motion.div
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.1 }}
        className='w-full'>
        <input type="text" placeholder='Saisissez le code promo' onChange={handleCodePromoChange} className="mt-1 rounded-lg py-2 px-3 border border-gray-400" />
      </motion.div> */}
      <motion.h3
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="font-bold relative text-gray-600">Sélectionnez le mode de paiement</motion.h3>
      {/*msg && (!modePaiement || !condition.condition) && <p className="text-red-500 text-base">Tous les champs sont obligatoires</p>*/}
      <div className='w-full sm:w-1/2 lg:w-1/3'>
        <div className='flex flex-col gap-4 w-full lg:w-96'>
          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className='w-full lg:w-96 mt-1 rounded-lg py-2 px-3 border border-gray-400 pl-6'
            style={(msg && !modePaiement) ? { border: "red solid 1px" } : {}}
          >
            <label className='cursor-pointer'>
              <input type="radio" name="paiement" value="Paiement en ligne" className='hidden' onChange={handlePaiementChange} />
              <span className="value flex items-center justify-between">Payer en ligne
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-0" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
              </span>
            </label>
          </motion.div>
          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className='text-sm border border-btngreen bg-green-100 text-green-600 flex items-center rounded-2xl w-fit py-1.5 px-6 self-end'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-4 text-btngreen" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={4}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
            </svg>
            Rapide et sécurisé
          </motion.div>
        </div>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className='w-full lg:w-96 mt-1 rounded-lg py-2 px-3 border border-gray-400 pl-6' style={(msg && !modePaiement) ? { border: "red solid 1px" } : {}}>
          <label className='cursor-pointer'>
            <input type="radio" name="paiement" value="Chèque" className='hidden w-full' onChange={handlePaiementChange} />
            <span className="value flex items-center justify-between">Chèque
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-0" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </span>

          </label>
        </motion.div>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className='w-full lg:w-96 mt-1 rounded-lg py-2 px-3 border border-gray-400 pl-6' style={(msg && !modePaiement) ? { border: "red solid 1px" } : {}}
        >
          <label className='cursor-pointer'>
            <input type="radio" name="paiement" value="Espèces" className='hidden w-full' onChange={handlePaiementChange} />
            <span className="value flex items-center justify-between">En espèces
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-0" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </span>
          </label>
        </motion.div>
      </div>
      <motion.div
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }} className='w-full text-xs text-gray-500'>
        <input type="checkbox" name="condition" id="" className='mr-2 shadow-red-500 border-red-500 ring-red-500 bg-red-500'
          style={(msg && !condition?.condition) ? { outline: "red solid 1px", height: "11px", width: "11px", borderRadius: "1.5px", "MozAppearance": "none", "WebkitAppearance": "none" } : {}}
          onChange={handleConditionChange} />J’accepte les <a href='https://www.saweblia.ma/cgu' target="_blank" rel="noopener noreferrer" className='text-blue-400'>conditions générale d’utilisation du services</a>
      </motion.div>
      <motion.div
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className='w-full text-xs text-gray-500'>
        <input type="checkbox" name="recevoir" id="" className='mr-2'
          onChange={handleConditionChange} />J’accepte de recevoir les offres de SERVICES A DOMICILE et ses partenaires</motion.div>
      <motion.p
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className='text-gray-500 text-sm'>
        Pour les paiements sur place, le montant sera exigé par le Pro à la fin de la prestation, avant de quitter les lieux. <br />
        Pour un paiement en espèces, pourriez-vous prévoir l’appoint :)
      </motion.p>
      <motion.button
        // disabled={!(modePaiement && condition.recevoir && condition.condition)}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className='rounded-2xl py-2 px-10 my-8 self-center text-white bg-btnblue-500 disabled:bg-btnblue-300/50'
        onClick={handleConfirm}>
        Passer commande
      </motion.button>

    </div>
  );
}

export default DevisPaiement;
