import React from "react"
import { motion } from "framer-motion";
import { ReactComponent as SVG } from "../../Assets/svg/google.svg"

const ButtonGoogle = () => {


  const handlegoogleLogin = () => {
    const width = 600, height = 600
    const left = (window.innerWidth / 2) - (width / 2)
    const top = (window.innerHeight / 2) - (height / 2)
    // const url = `${API_URL}/${provider}?socketId=${socket.id}`

    // window.open("https://microservices.saweblia.ma:15000/auth/google", 'google_auth',
    //   `toolbar=no, location=no, directories=no, status=no, menubar=no, 
    //       scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
    //       height=${height}, top=${top}, left=${left}`
    // )

    
    window.open("https://microservices.saweblia.ma:15000/auth/google", 'google_auth',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
          scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
          height=${height}, top=${top}, left=${left}`
    )




  }
  return (
    <motion.button
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="mt-6 w-fit"
      onClick={handlegoogleLogin}
    >
      <motion.div
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-white px-11 py-2 text-center rounded-3xl my-2 flex gap-1 font-medium text-lg items-center justify-center bg-[#283995]"
      //onClick={()=>{setCurrentStep(5)}}
      >
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" className="h-5 w-5 mr-2 fill-white" >
          <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
        </svg> */}
        <SVG className="mr-1" />
        Continuez avec Google
      </motion.div>
    </motion.button>

  )

}

export default ButtonGoogle