import axios from "axios"
import Endpoint from "./endpoint"

const getPrestationsById = async (idPrestation) => {
  const result = await axios.get(`${Endpoint}newprestation/${idPrestation}`)
  return result.data.data
}

const getAllPrestations = async () => {
  const response = await axios.get(`${Endpoint}newprestations`)
  return response.data.data
}

const searchPrestation = async (query) => {
  const response = await axios.get(`${Endpoint}newprestations/search/${query}`)
  return response.data.data
}

export {
  getPrestationsById,
  getAllPrestations,
  searchPrestation,
}