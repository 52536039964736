import axios from "axios"
import Endpoint from "./endpoint"

const getClientById = async (idClient) => {
  const result = await axios.get(`${Endpoint}client/${idClient}`)
  return result.data.data
}

export {
  getClientById,
}