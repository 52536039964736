import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Context from "../context/context"
const NavBar = () => {
  const { clearContext } = useContext(Context)

  const handleLogout = () => {
    localStorage.clear()
    clearContext()
    window.location.replace("https://app.saweblia.ma/profil")
  }

  return (
    <div className='w-full py-2'>
      <div className="container my-2 sm:my-3 px-2 md:px-0 mx-auto max-w-[58rem] flex items-center">
        <a
          rel="noopener noreferrer" href="https://saweblia.ma"
        >
          <img
            className="h-8 sm:h-9 md:h-10 lg:h-12 w-auto"
            src="https://uploads-ssl.webflow.com/617a6a81ab2a9670b3643c33/617a6a81ab2a966c45643c3f_logo%20saweblia%20couleur_2%201.png"
            alt="Workflow"
          /></a>
        {/*  <Link to="/">
          <button onClick={() => { }}
            className="px-3 py-2 bg-indigo-900 text-white rounded-xl">
            Demander un service
          </button> </Link> */}
      </div>
      <div className="gradient px-2 md:px-8 py-2 sm:py-4 mb-6">
        <div className="flex gap-x-2 items-center container mx-auto max-w-[58rem]">
          <Link to="/">
            <svg xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-btnyellow cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg></Link>
          {
            JSON.parse(localStorage.getItem("user"))?.idClient &&
            <div className=" float-right ml-auto items-center inline-flex gap-4">
              <span className="text-white whitespace-nowrap">{JSON.parse(localStorage.getItem("user"))?.idClient?.nom}</span>
              <svg onClick={handleLogout} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-6 w-6 fill-btnyellow cursor-pointer" stroke="currentColor" strokeWidth={1}>
                <path d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z" />
              </svg>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default NavBar;
