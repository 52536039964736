import React from 'react';
import { useContext } from "react";
import Context from "../../context/context";
import { motion } from "framer-motion";
import formatNumber from "../../utils/formatPrice";
import timeConvert from "../../utils/timeConvert";
import _ from "lodash"

const DevisComponent = ({ currentStep }) => {

  const { prestation, adresse, totalPrice, totalHour, disponibiliteDate, creneauHoraire } = useContext(Context)
  return (
    <div className='px-2'>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="gradient text-white px-4 py-2 flex flex-col gap-2 items-center w-full md:w-[34rem]">

        <motion.h3
          initial={{ y: 10 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
          className='underlinedPrestation w-fit relative text-center mb-6'>Prestations à réaliser par le Pro</motion.h3>

        <motion.ul className='prestation-list text-white self-start mb-4 my-2'>
          {
            prestation.map((pres) => (
              <motion.li key={pres.id}>{pres?.prestation?.libelle} {/* <span className='text-green-400 font-semibold'> x {pres.unite}</span> */}
              </motion.li>
            ))
          }
        </motion.ul>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-md pl-3 pr-2 sm:pr-12 py-4 flex flex-col text-black w-full">
          <div className='flex flex-col gap-1'>
            <div className="flex flex-col sm:flex-row justify-between gap-1 sm:gap-2">
              <span>Durée estimée des travaux</span>
              <span className='font-bold pl-px'>{_.find(prestation.map(p => p.prestation), function(p) { return p?.presForfaitaire }) ? _.find(prestation.map(p => p.prestation), function(p) { return p?.presForfaitaire }).presForfaitaireDesc : timeConvert(totalHour)}</span>
            </div>

            <div className="flex flex-col sm:flex-row justify-between gap-1 sm:gap-2">
              <span>Tarif*</span>
              <span className='font-bold pl-px'>{formatNumber(totalPrice)} Dhs</span>
            </div>
          </div>
        </motion.div>

        <span className='text-xs self-start ml-2 font-thin'>* Toutes taxes comprises - hors fourniture ou matériel de remplacement</span>
        <span className='text-xs self-start ml-2 font-thin'>* Inclus frais de déplacement 50 Dhs toutes taxes comprises</span>
        {
          disponibiliteDate && creneauHoraire &&
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-md pl-3 pr-2 sm:pr-12 py-4 flex flex-col text-black w-full gap-1"
          >
            <div className="flex flex-col sm:flex-row justify-between gap-1 sm:gap-2">
              <span>Intervention souhaitée {typeof (disponibiliteDate) !== "string" && "le"}</span>
              {typeof (disponibiliteDate) !== "string" ?
                <span className='font-bold pl-px'>{disponibiliteDate?.toLocaleDateString("fr-FR", { month: 'long', day: 'numeric', year: "numeric" })}</span>
                :
                <span className='font-bold pl-px'>{disponibiliteDate}</span>
              }

            </div>

            <div className="flex flex-col sm:flex-row justify-between gap-1 sm:gap-2">
              <span>Créneau de passage </span>
              <span className='font-bold pl-px'>{creneauHoraire}</span>
            </div>
          </motion.div>
        }
        {
          localStorage.getItem("user") &&
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-md pl-3 pr-2 my-2 sm:pr-12 py-4 flex flex-col text-black w-full">
            <div className='flex flex-col'>
              <div className="flex flex-col sm:flex-row justify-between gap-1 sm:gap-2">
                <span>Client</span>
                <span className='font-bold pl-px'>{JSON.parse(localStorage.getItem("user"))?.idClient?.nom}</span>
              </div>

              <div className="flex flex-col sm:flex-row justify-between gap-1 sm:gap-2">
                <span>Tél.</span>
                <span className='font-bold pl-px'>{JSON.parse(localStorage.getItem("user"))?.idClient?.tele}</span>
              </div>
            </div>
          </motion.div>
        }
        {
          adresse?.addresse && adresse?.quartier && adresse?.ville &&
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-md pl-3 pr-2 sm:pr-12 py-4 flex flex-col text-black w-full">
            <div className='flex flex-col'>
              <div className="flex flex-col gap-2">
                <span>L’intervention aura lieu à : </span>
              </div>

              <div className="flex flex-col gap-2">
                <span className='font-bold'> {/*"Rue de la Paix, 9éme étage Appart 69 - 5éme étage rue de la paix  - Bourgogne, Casablanca"*/}
                  {`${adresse.addresse} - ${adresse.quartier}  ,  ${adresse.ville}`}
                </span>
              </div>
            </div>
          </motion.div>
        }
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-white py-4 px-2 h-max w-full max-w-lg self-center min-w-max">
          <div className="flex flex-col">
            <h5 className='yellowUnderLine relative mb-6'>Inclus dans la prestation : </h5>
            <ul className='info-list ml-2'>
              <li>Frais de déplacement</li>
              <li>Nettoyage après intervention</li>
              <li>Main d'oeuvre</li>
              <li>Garantie "Satisfait ou refait"</li>
            </ul>
          </div>
        </motion.div>
      </motion.div>
    </div>

  );
}

export default DevisComponent;
