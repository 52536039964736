import climatisation from '../Extract/Climatisation,ventilation.json'
import plomberie from '../Extract/Plomberie.json'
import porte from '../Extract/Portes,fenetres,volets.json'
import electromenager from "../Extract/Electromenager.json"
import electricite from '../Extract/Electricite.json'

export default [
  {
    "question": "Plomberie",
    "id": "1cfc2348-5963-4f4d-8620-5858fb3c547f",
    "stepName": "Quelle est la nature du problème ?",
    "stepNumber": "0",
    "component": "list",
    "children": plomberie
  },
  {
    "question": "Electricité",
    "id": "3ffd0761-c8c8-4486-a8a1-5d25cd6cbc46",
    "stepName": "Où se situe le problème ?",
    "stepNumber": "0",
    "component": "list",
    "children": electricite
  },
  {
    "question": "Porte, fenêtre et volet",
    "id": "811c567d-7aba-48f8-a33a-5bc2a8950237",
    "stepName": "Où se situe le problème ?",
    "stepNumber": "0",
    "component": "list",
    "children": porte
  },
  /* {
     "question": "Electroménager",
     "id": "b4824a42-4361-40e5-91af-09096868b563",
     "stepName": "Où se situe le problème ?",
     "stepNumber": "0",
     "component": "list",
     "children": electromenager
   },*/
  {
    "question": "Climatisation",
    "id": "6e952c20-da28-4d8f-a813-45016fc56979",
    "stepName": "Où se situe le problème ?",
    "stepNumber": "0",
    "component": "list",
    "children": climatisation
  },
  {
    "question": "J’ai besoin de plusieurs prestations",
    "id": "ce135d9e-2bdb-4662-a652-d8ff459a526a",
    "stepNumber": "0",
    "demandepersonalise": true,
    "component": "demande-personalise"
  }
]