import React, { useEffect } from 'react';
import DevisInfo from './DevisInfo';
import DevisComponent from './DevisComponent'

const SideDevis = ({ currentStep, setCurrentStep, path }) => {

  /*  useEffect(() => {
     path && path.forEach(elm => {
       elm.libelle && console.log("libelle ", elm.libelle);
       elm.question && console.log("question ", elm.question);
       elm.stepName && console.log("stepName ", elm.stepName);
     })
   }, []) */

  return (
    <div className={currentStep === 1 ? 'flex' : "hidden md:flex"}>
      {currentStep === 1 || currentStep > 8 ?
        <DevisInfo />
        :
        <DevisComponent currentStep={currentStep} />
      }
    </div>
  );
}

export default SideDevis;
