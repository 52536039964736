import { motion } from "framer-motion";
import { useContext } from "react";
import Context from "../../context/context"
import { getPrestationsById } from "../../services/prestation";
import { sortAnswers } from "../../utils/sortAnswers";

/* function formatNumber(num) {
  var number = Number(num).toFixed(2);
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}
function timeConvert(n) {
  var num = n;
  var hours = (num / 1);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours === 0 ? rminutes + "min" : rhours + "h " + (rminutes === 0 ? "" : rminutes + "min")
  // num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
} */

const List = ({ step, selectStep }) => {

  const { setInputs, famille, setShowDevis, setPrestation, setPrice, setTotalHour } = useContext(Context)

  const GenerateDevis = async (child) => {
    let childWithPrestation = { ...child, prestation: await getPrestationsById(typeof child?.prestation === "string" ? child?.prestation : child?.prestation?._id) }
    const calDuration = childWithPrestation?.prestation?.quantite + ((childWithPrestation?.prestation?.quantite - 1) * childWithPrestation?.prestation?.Coefficient)
    const prixVente = childWithPrestation?.prestation?.prixAchat / 0.7 // taux de marc
    console.log("prix achat", childWithPrestation?.prestation?.prixAchat)
    let value = [{
      ...childWithPrestation,
      unite: 1,
      value: 1,
      marge: 30,
      nbrPres: 1,
      quantite:calDuration,
      prixVente: prixVente,
      calDuration: calDuration
    }]
    childWithPrestation.prestation.nbrPres = 1;
    childWithPrestation.prestation.marge = 30;
    childWithPrestation.prestation.prixVente = prixVente;
    childWithPrestation.prestation.quantite = calDuration;
    const tva = ((prixVente * calDuration - childWithPrestation?.prestation?.prixAchat * calDuration) * 0.2)
    const price = calDuration * prixVente + tva + 50 + 3
    setShowDevis(true);
    setInputs(value);
    setPrestation(value);
    setPrice(price);
    setTotalHour(childWithPrestation?.prestation?.quantite);
  }

  return (
    <div className="flex flex-col items-center px-2">

      {famille && <h4 className="StepTitle relative text-center  font-medium md:font-semibold w-full text-2xl" >{famille}</h4>}
      <div className="sm:w-96 w-80 text-textgray-dark/80 text-xl underlined relative mt-12 mb-4 mx-auto font-semibold text-center">
        <h3>{step.stepName}</h3>
      </div>
      <motion.ul
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="mt-3 w-full md:w-fit"
      >
        {step?.children.map((child) => (
          <motion.li
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            key={child.id}
            onClick={() => (!child.children && !child.formulaire) ? GenerateDevis(child) : selectStep(child.id)}
            className="text-white px-2 sm:px-6 md:px-20 lg:px-28 py-1 text-center rounded-2xl my-4 cursor-pointer even:bg-btnblue-600/80 odd:bg-btnblue-600"
          >
            {child.question}
          </motion.li>
        ))}
      </motion.ul>
    </div>
  );
};

export default List;
