import React, { useEffect, useContext, useRef, useState } from 'react';
import { motion } from "framer-motion";
import { ReactComponent as SVG } from '../../Assets/svg/cmdConfirm.svg'
import Context from "../../context/context";
import lottie from "lottie-web";
import animationFile from "../../Assets/lottieFile/87985-delivery-scooter.json";

let animation = null
const CommandeConfirm = () => {

  const [animationCompleted, setAnimationCompleted] = useState(false)
  const { setPrevStep, client, commandeRef } = useContext(Context);
  const container = useRef()

  useEffect(() => {
    setPrevStep(null)
  }, [])

  useEffect(() => {
    animation = lottie.loadAnimation({
      container: container.current, // current instance of our container!
      animationData: animationFile, // animation file!
      renderer: "svg",
      loop: false,
      autoplay: true
    });

    animation.addEventListener("complete", () => {
      setAnimationCompleted(true)
    })
  }, [])

  useEffect(() => {
    window.scroll({
      top: 120,
      left: 0,
      behavior: 'smooth'
    });
  }, [])

  return (

    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className='flex flex-col items-center text-xl leading-9 text-center h-full px-2'>
      {/* <h1 className="text-2xl font-bold StepTitle relative text-center my-12">Votre commande a été prise en compte</h1> */}
      <motion.p>Merci <span className='font-bold'>{client?.nom}</span> </motion.p>
      <motion.p>Nous vous remercions de votre confiance.</motion.p>
      {commandeRef && <motion.p>Votre numéro de votre commande est <span className='font-bold'>{commandeRef}</span></motion.p>}
      <div ref={container} className="w-full my-2 h-40">

      </div>
      <motion.p>Vous êtes contacté <span className='font-bold'>30 minutes avant le passage</span> du Pro chez vous.</motion.p>
      <motion.div className='flex flex-col items-center mt-10 text-base'>
        <motion.h3 className='text-[#283995] font-bold StepTitle relative mb-8'>Besoin d'aide ?</motion.h3>
        <motion.p className='text-[#283995]'>En cas de questions ou de problèmes, n’hésitez pas à contacter notre Service Client +212-677330404</motion.p>
        <motion.p className='text-[#283995]'>Du Lun au Sam de 9h à 19h</motion.p>
      </motion.div>
    </motion.div>
  );
}

export default CommandeConfirm;
