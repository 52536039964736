import { useState, useEffect, useContext } from "react";
import "./styles.css";
import Steps from "./Data/treeSteps";
import List from "./Components/Workflow/List";
import InputNumberList from "./Components/Workflow/InputNumberList";
import QuestionAnswer from "./Components/Workflow/QuestionAnswer";
import AnswerList from "./Components/Workflow/AnswerList";
import NavList from "./Components/Workflow/NavList";
import FamilleList from "./Components/Workflow/FamilleList"
import Devis from "./Components/Devis/SideDevis";
import DevisCalendar from "./Components/Devis/DevisCalendar";
import DevisTime from "./Components/Devis/DevisTime";
import DemandePersonalise from "./Components/Workflow/DemandePersonalise";
import _ from "lodash";
import deepdash from "deepdash";
import Context from "./context/context"
import { motion } from "framer-motion";
import Identification from './Components/authentication/Identification';
import Login from './Components/authentication/Login';
import Register from './Components/authentication/Register';
import DevisAddress from './Components/Devis/DevisAddress';
import DevisPaiement from './Components/Devis/DevisPaiement';
import CommandeConfirm from './Components/Devis/CommandeConfirm';
import DemandeConfirm from './Components/Devis/DemandeConfirm'
import DevisPrestation from './Components/Devis/DevisPrestation'
import DevisRecapitulatif from './Components/Devis/DevisRecapitulatif'
import { searchPrestation, getPrestationsById } from './services/prestation'
import { useNavigate, useSearchParams } from "react-router-dom"
import classNames from "./utils/classNames"
import { v1 as uuid } from 'uuid';
import axios from "axios";
import ENDPOINT from "./services/endpoint";
// import YourReCaptchaComponent from "./hooks/useHooks"

deepdash(_);

export default function App() {

  const [currentStep, setCurrentStep] = useState(null);
  const [IdCurrentStep, setIdCurrentStep] = useState("root-step");
  const [previousStep, setPreviousStep] = useState(null);
  const [searchResultData, setSearchResultData] = useState([]);
  const [isSearchPrestation, setIsSearchPrestation] = useState(false);
  const [parents, setParents] = useState([]);
  const [devisStep, setDevisStep] = useState(1)
  const [devisStepTitle, setDevisStepTitle] = useState("Devis")
  const [searchParams] = useSearchParams();
  //const [stateE,setStateE] = YourReCaptchaComponent()
  const { showDevis, setInputs, setAdresse, setPrestation, setShowDevis, setCreneauHoraire, setdisponibiliteDate,
    setPrice, setTotalHour, prevStep, browserId, setBrowserId, clearContext, setselectedFamille } = useContext(Context)

  const navigate = useNavigate()
  //console.log(stateE)

  const findStep = (id = IdCurrentStep) => {
    let step = _.findDeep(Steps, (item) => item.id === id, {
      childrenPath: "children"
    });
    return step;
  };
  /*
  const findStringPath = (id) => {
    let path = _.findPathDeep(Steps, (item) => item.id === id, {
      childrenPath: "children",
      pathFormat: "array"
    });
    let stringPath = _.pathToString(path);
    //console.log(stringPath);
    return stringPath;
  };
*/
  /* A function that takes a step as a parameter and returns a component based on the step.component
  property. */
  const renderStep = (step) => {
    let component = step?.component
    switch (component) {
      case "list":
        return (
          <List
            step={step}
            selectStep={SelectStep}
          />
        );
      case "input-number-list":
        return <InputNumberList step={step} selectStep={SelectStep} />;
      case "question_answer":
        return <QuestionAnswer step={step} />;
      case "answer-list":
        return <AnswerList step={step} selectStep={SelectStep} />;
      case "nav-list":
        return <NavList step={step} selectStep={SelectStep} />;
      case "famille-list":
        return <FamilleList step={step} selectStep={SelectStep} />;
      case "demande-personalise":
        return <DemandePersonalise step={step} selectStep={SelectStep} path={parents} />;
      default:
    }
  };
  //**************************idBrowser */
  const postIdBrowser = async () => {
    const idBrowser = uuid()
    if (!browserId) {
      setBrowserId(idBrowser)
      const response = await axios.post(`${ENDPOINT}setIdBrowser`, { idBrowser: idBrowser || browserId })
    }
  }
  useEffect(() => {
    postIdBrowser()
  }, [])

  const getParents = (id) => {
    if (!id || typeof id !== "string") return
    let parentsArray = [];
    var idStep = id;
    parentsArray.push(findStep(id).value);
    while (idStep !== "root-step") {
      _.eachDeep(
        Steps,
        (item) => {
          if (item.id === idStep) {
            let step = findStep(item.id)
            parentsArray.push(step.parent);
            idStep = step.parent?.id;
          }
        },
        {
          childrenPath: "children"
        }
      );
    };
    parentsArray = parentsArray.filter(elm => elm.id !== "root-step").reverse()
    console.log(parentsArray);
    setParents(parentsArray)
  };

  const SelectStep = (id) => {
    setIdCurrentStep(id);
    getParents(id);
    //console.log(findStringPath(id));
    /*console.log(
      ` ${Steps.libelle} > ${Steps.children[0].libelle} > ${Steps.children[0].children[2].libelle}`
    );
    */
  };

  const handleSearchChange = async (e) => {
    let searchresult = []
    if (e.target.value === "") {
      setIsSearchPrestation(false);
      SelectStep("root-step");
      setSearchResultData(searchresult);
      return
    }
    searchresult = await searchPrestation(e.target.value);
    setIsSearchPrestation(true)
    setSearchResultData(searchresult);
  };

  const findStepOne = (step) => {
    let findstep = _.findDeep(Steps, (item) => item?.id === step?.id, {
      childrenPath: "children"
    });
    if (!findstep) return null
    if (findstep?.parent?.stepNumber === "1") return findstep.parent
    else return findStepOne(findstep.parent)

  }

  const findFamille = (id) => {
    let findstep = _.findDeep(Steps, (item) => item?.id === id, {
      childrenPath: "children"
    })
    console.log("findfamille", findstep);
    if (!findstep) return null
    if (findstep?.value?.stepNumber === "0") return findstep.value
    else return findFamille(findstep.parent.id)

  }

  const SelectStepSearch = (id) => {
    let parent = _.findDeep(Steps, (item) => item?.prestation === id || item?.idPrestation === id, {
      childrenPath: "children"
    })?.value
    if (!parent) return null
    console.log(parent)
    setselectedFamille(findFamille(parent.id).question);
    if (parent?.stepNumber !== "1") {
      let stepOne = findStepOne(parent)
      setIdCurrentStep(stepOne?.id);
      getParents(stepOne?.id);
    }
    else {
      setIdCurrentStep(parent?.id);
      getParents(parent?.id);
    }
  }

  /***********************************Devis*********************************/

  const renderDevisStep = () => {
    let step = devisStep
    switch (step) {
      case 0:
        return <div />;
      case 1:
        return <DevisPrestation currentStep={devisStep} setCurrentStep={setDevisStep} path={parents} />;
      case 2:
        return <DevisCalendar currentStep={devisStep} setCurrentStep={setDevisStep} />;
      case 3:
        return <DevisTime currentStep={devisStep} setCurrentStep={setDevisStep} />;
      case 4:
        return <Identification currentStep={devisStep} setCurrentStep={setDevisStep} />;
      case 5:
        return <Login currentStep={devisStep} setCurrentStep={setDevisStep} />;
      case 6:
        return <Register currentStep={devisStep} setCurrentStep={setDevisStep} />;
      case 7:
        return <DevisAddress currentStep={devisStep} setCurrentStep={setDevisStep} />
      case 8:
        return <DevisPaiement currentStep={devisStep} setCurrentStep={setDevisStep} path={parents} />;
      case 9:
        return <DevisRecapitulatif currentStep={devisStep} setCurrentStep={setDevisStep} path={parents} />;
      case 10:
        return <CommandeConfirm />
      default:
    }
  };

  const PreviousDevisStep = () => {
    let previousStep = prevStep || devisStep - 1
    if (previousStep === 1) { setCreneauHoraire(""); setdisponibiliteDate({}) }
    if (previousStep === 0) {
      setShowDevis(false)
      setDevisStep(1)
      setIdCurrentStep(parents[parents.length - 1].id)
    }
    if (previousStep >= 1) setDevisStep(previousStep)

  }

  const handleLogout = () => {
    localStorage.clear()
    clearContext()
    window.location.replace("http://microservices.saweblia.ma:5111/")

  }

  useEffect(() => {
    setCurrentStep(findStep()?.value);
  }, []);

  useEffect(() => {
    setCurrentStep(findStep().value);
    setPreviousStep(findStep().parent);
  }, [IdCurrentStep]);

  const getParams = async () => {

    let page = searchParams.get("page")
    let id = searchParams.get("id")

    switch (page) {
      case "profil":
        navigate("/profil")
        break;
      case "devis":
        if (id) navigate(`devis/${id}`)
        else navigate("/")
        break;
      case "demande":
        navigate("/demande")
        break;
      default:
        navigate("/")
        break
    }
    let categorie = searchParams.get("categorie")

    if (categorie) {
      setIdCurrentStep(categorie)
      setShowDevis(false);
    }


    let ville = searchParams.get("ville")
    let presta = searchParams.get("prestation")
    ville && setAdresse({ ville: ville })
    if (presta) {
      let getPrestation = await getPrestationsById(presta)
      const calDuration = getPrestation?.quantite + ((getPrestation?.quantite - 1) * getPrestation?.Coefficient)
      const prixVente = getPrestation?.prixAchat / 0.7 // taux de marc
      console.log(getPrestation);
      let value = [{
        prestation: getPrestation,
        unite: 1,
        value: 1,
        marge: 30,
        nbrPres: 1,
        prixVente: prixVente,
        quantite: calDuration,
      }]
      getPrestation.nbrPres = 1
      getPrestation.marge = 30
      getPrestation.prixVente = prixVente;
      const tva = ((prixVente * calDuration - getPrestation?.prixAchat * calDuration) * 0.2)
      const price = calDuration * prixVente + tva + 50 + 3
      setShowDevis(true);
      setInputs(value);
      setPrestation(value);
      setPrice(price);
      setTotalHour(getPrestation?.quantite)
    }
  }
  useEffect(() => {
    getParams()
  }, [searchParams])

  useEffect(() => {
    let step = devisStep
    switch (step) {
      case 1:
        setDevisStepTitle("Devis")
        break;
      case 2:
        setDevisStepTitle("Devis")
        break;
      case 3:
        setDevisStepTitle("Devis")
        break;
      case 4:
        setDevisStepTitle("Identifiez-vous")
        break;
      case 5:
        setDevisStepTitle("Content de vous revoir !")
        break;
      case 6:
        //setDevisStepTitle("Où doit-on intervenir ?")
        setDevisStepTitle("Identifiez-vous")
        break;
      case 7:
        setDevisStepTitle("Où doit-on intervenir ?")
        //setDevisStepTitle("Récapitulatif de votre commande")
        break;
      case 8:
        setDevisStepTitle("Récapitulatif de votre commande")
        break;
      case 9:
        setDevisStepTitle("Récapitulatif de votre commande")
        //setDevisStepTitle("Votre commande a été prise en compte")
        //setDevisStepTitle("Votre demande a été prise en compte")
        break;
      case 10:
        setDevisStepTitle("Votre commande a été prise en compte")
        //setDevisStepTitle("Votre demande a été prise en compte")
        break;
      default:
        setDevisStepTitle("Devis")
        break;
    }
  }, [devisStep])

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [])
  return (
    <div className="flex flex-col py-2 h-full">
      <div className="container my-2 sm:my-3 px-8 mx-auto max-w-[1280px] flex items-center">
        <a
          rel="noopener noreferrer" href="https://www.saweblia.ma/"
        >
          <img
            className="h-8 sm:h-9 md:h-10 lg:h-12 w-auto cursor-pointer"
            src="https://uploads-ssl.webflow.com/617a6a81ab2a9670b3643c33/617a6a81ab2a966c45643c3f_logo%20saweblia%20couleur_2%201.png"
            alt="Saweblia"
          /></a>
        {/*<button onClick={() => { SelectStep("root-step"); setShowDevis(false); clearContext(); setDevisStep(1) }}
          className="px-3 py-2 bg-indigo-800 text-white rounded-xl">
          Demander un service
  </button>*/}
      </div>
      <div className="gradient px-3 sm:px-28 lg:px-36 py-2 sm:py-4 mb-2">
        <div className="flex gap-1 items-center container mx-auto max-w-[1280px]">
          {!showDevis ?
            <div className="flex items-center sm:ml-5 gap-2">

              <svg xmlns="http://www.w3.org/2000/svg"
                onClick={() => { SelectStep("root-step"); setIdCurrentStep("root-step"); setCurrentStep(findStep().value); setShowDevis(false); clearContext(); setDevisStep(1) }}
                className="h-8 w-8 text-btnyellow cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>

              {
                currentStep?.stepNumber !== "-1" &&
                <>
                  <button className="bg-[#FDB813] rounded-lg py-1 px-8 ring-0 hidden md:block"
                    hidden={IdCurrentStep === "root-step" || searchResultData.length > 0}
                    onClick={() => SelectStep(previousStep.id)}
                  >
                    Retour
                  </button>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="h-6 w-6 md:hidden fill-btnyellow cursor-pointer"
                    hidden={IdCurrentStep === "root-step" || searchResultData.length > 0}
                    onClick={() => SelectStep(previousStep.id)}
                  >
                    <path d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z" />
                  </svg>
                </>
              }
            </div>
            :
            <>

              <div className="flex items-center sm:ml-5 gap-2">
                <svg xmlns="http://www.w3.org/2000/svg"
                  onClick={() => { SelectStep("root-step"); setShowDevis(false); clearContext(); setDevisStep(1) }}
                  className="h-8 w-8 text-btnyellow cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                </svg>
                {
                  devisStep !== 10 &&
                  <>
                    <button className="bg-[#FDB813] rounded-lg py-1 px-8 ring-0 hidden md:block"
                      onClick={PreviousDevisStep}
                    >
                      Retour
                    </button>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="h-6 w-6 md:hidden fill-btnyellow cursor-pointer"
                      onClick={PreviousDevisStep}
                    >
                      <path d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z" />
                    </svg>
                  </>
                }
              </div>


            </>
          }

          {
            !showDevis &&


            <div className="mt-1 relative shadow-sm px-2 py-px focus:ring-0 focus:outline-none rounded-md w-full mx-auto">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
              </div>
              <input
                type="text"

                onChange={(e) => handleSearchChange(e)}
                className="pl-8 px-2 py-1 focus:ring-0 focus:outline-none rounded-md w-full mx-auto text-base md:text-lg"
                placeholder="Rechercher un service"
              />
            </div>

          }

          {
            JSON.parse(localStorage.getItem("user"))?.idClient &&
            <div className=" float-right ml-auto items-center inline-flex gap-4">
              <span className="text-white hidden md:block whitespace-nowrap">{JSON.parse(localStorage.getItem("user"))?.idClient?.nom}</span>
              <svg onClick={handleLogout} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-6 w-6 fill-btnyellow cursor-pointer" stroke="currentColor" strokeWidth={1}>
                <path d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z" />
              </svg>
            </div>
          }
        </div>
      </div>

      <div className="container mx-auto max-w-[1280px] pb-2 mb-4 mt-2 md:mt-4 h-full">
        {
          (currentStep?.stepNumber !== "-1" && devisStep !== 10 && currentStep?.component !== "demande-personalise") &&
          <div className="grid grid-cols-3 gap-1 mb-6 px-2">
            <div className={`h-1 w-full ${(!showDevis || (showDevis && devisStep === 1)) ? "bg-btnyellow" : "bg-textgray-light"}`}></div>
            <div className={`h-1 w-full ${(showDevis && (devisStep < 9 && devisStep > 1)) ? "bg-btnyellow" : "bg-textgray-light"}`}></div>
            <div className={`h-1 w-full ${(showDevis && devisStep === 9) ? "bg-btnyellow" : "bg-textgray-light"}`}></div>
          </div>
        }
        {
          !showDevis ?

            <div className="h-full">
              {
                isSearchPrestation &&
                <>
                  {
                    searchResultData.length > 0 ?
                      <div className="flex flex-col items-center px-2">
                        <motion.ul
                          className="w-fit">
                          {_.uniqBy(searchResultData, "SearchLibelle").map((data, i) => (
                            <motion.li
                              initial={{ scale: 0.9 }}
                              animate={{ scale: 1 }}
                              transition={{ duration: 0.5, delay: i / 100 }}
                              whileHover={{ scale: 1.2 }}
                              className="text-white even:bg-btnblue-600/80 odd:bg-btnblue-600 px-2 sm:px-6 md:px-20 lg:px-28 py-1 text-center rounded-2xl my-2 md:my-4 cursor-pointer"
                              key={data._id}
                              onClick={() => {
                                SelectStepSearch(data?._id);
                                setSearchResultData([]);
                                setIsSearchPrestation(false)
                              }}
                            >
                              {data.SearchLibelle}
                            </motion.li>
                          ))}
                        </motion.ul>
                      </div>
                      :

                      <div className="flex flex-col justify-center items-center text-center w-full mt-2">
                        <p>Aucun résultat trouvé</p>
                        <span
                          className="text-white even:bg-btnblue-600/80 odd:bg-btnblue-600 px-6 md:px-20 lg:px-28 py-1 text-center rounded-2xl my-2 md:my-4 cursor-pointer"
                          onClick={() => {
                            setCurrentStep({
                              "question": "",
                              "id": "Aucunresultat",
                              "stepNumber": "0",
                              //"demandepersonalise": true,
                              "component": "demande-personalise",
                              "Prestation": "Formulaire “Demande personnalisé”",
                              "formulaire": true
                            });
                            setIsSearchPrestation(false);
                          }}>Je ne trouve pas le service</span>
                      </div>
                  }
                </>
              }
              {!isSearchPrestation &&
                currentStep &&
                <>
                  {/* <div className="inline-flex text-blue-500 font-semibold my-1">
                  {
                    parents.map((parent) => (
                      <span key={parent.id} style={{ cursor: "pointer" }} onClick={() => SelectStep(parent.id)}>{parent.question || parent.libelle} {" > "}</span>
                    ))}
                </div> */}


                  {/*  <div className="inline-flex text-blue-500 font-semibold my-4">
                  {parents.map((parent) => (
                    <span key={parent.id} style={{ cursor: "pointer" }} onClick={() => SelectStep(parent.id)}>{parent.stepName} {" > "}</span>
                  ))}
                </div> */}

                  <div className="flex justify-center items-center">
                    {
                      renderStep(
                        currentStep
                      )
                    }
                  </div>
                </>
              }
            </div>
            :
            <div className="h-full">
              <div>


                <h2 className="StepTitle relative text-center font-semibold w-full text-2xl mb-9" >{devisStepTitle}</h2>
              </div>
              <div
                // className={(devisStep !== 10) ?
                //"mt-14 px-4 w-full flex flex-col items-center lg:items-start lg:flex-row gap-5 lg:justify-between justify-center"
                //: "flex flex-row items-start justify-center w-full"}

                className={
                  classNames(
                    "flex w-full gap-y-2 gap-x-5 mt-2 justify-center flex-wrap",
                    devisStep === 1 || devisStep === 9 ? "flex-col items-center" : "md:px-5 md:justify-around"
                  )
                }
              >
                {
                  renderDevisStep()
                }
                {(devisStep !== 10 && devisStep !== 1 && devisStep !== 9) && <Devis currentStep={devisStep} setCurrentStep={setDevisStep} path={parents} />}
              </div>
              {devisStep === 6 && <p className="text-sm text-center font-thin m-4 ">Conformément à la loi 09-08, vous disposez d’un droit d’accès, de rectification et d’opposition au traitement de vos données personnelles. Ce traitement a été autorisé par la CNDP sous le n° D-GC-280/220</p>}
            </div>
        }

      </div>
{/* <YourReCaptchaComponent/> */}
      {/* <CommandeConfirm /> */}
      {/* <DemandeConfirm /> */}
    </div >
  );
}
