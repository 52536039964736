import React from 'react';
import Header from '../Layout/Header'
import App from '../App'

const Home = () => {
  return (
    <div className=''>
      <Header title={"Saweblia | Home"}>
        <App />
      </Header>
    </div>
  );
}

export default Home;
