import React, { useState, useContext, useEffect } from 'react';
import Context from '../../context/context';
import { Calendar } from 'react-rainbow-components';
import { motion } from 'framer-motion';
import moment from 'moment';

export default function DevisCalendar({ currentStep, setCurrentStep }) {
	const [date, setDate] = useState(null);
	const [noSundayError, setNoSundayError] = useState(false);
	const { setdisponibiliteDate, setPrevStep } = useContext(Context);

	const handleCalenderChange = (date) => {
		if (date.getDay() === 0) setNoSundayError(true);
		else {
			setNoSundayError(false);
			setDate(date);
			setdisponibiliteDate(date);
			setCurrentStep(3);
		}
	};

	const HandleCheckBoxChange = (e) => {
		if (e.target.checked) {
			setdisponibiliteDate(new Date(Date.now()));
			setCurrentStep(3);
		}
	};

	useEffect(() => {
		setPrevStep(null);
	}, []);

	return (
		<div className='flex flex-col items-center gap-3 w-full lg:w-2/5 px-2'>
			<h3 className='font-bold text-center underlined text-gray-600 relative'>
				Quel jour vous convient le mieux ?
			</h3>
			<motion.div
				initial={{ x: 20, opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				transition={{ duration: 0.5 }}
				className='flex mt-4 cursor-pointer items-baseline'
			>
				<label className='cursor-pointer text-center'>
					<input
						type='checkbox'
						className='mr-1 cursor-pointer'
						onChange={HandleCheckBoxChange}
					/>
					Je n’ai pas de préférence, aussi vite que possible !
				</label>
			</motion.div>
			<motion.div
				initial={{ x: 20, opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				transition={{ duration: 0.5, delay: 0.2 }}
			>
				<Calendar
					id='calendar-3'
					value={date}
					locale='fr-Fr'
					minDate={new Date(Date.now())}
					onChange={handleCalenderChange}
					className='w-72 md:w-96 my-3'
					disabledDays={[new Date('2022/07/28')]}
				/>
				{/* <Calendar
          id="calendar-5"
          value={date}
          onChange={handleCalenderChange}
          disabledDays={[`Wed Jul 27 2022`]}
        /> */}
			</motion.div>
			{noSundayError ? (
				<h3 className='text-center text-red-500'>
					Nos services ne sont pas disponibles les Dimanches.
					<br /> Merci de choisir une autre date.
				</h3>
			) : null}
		</div>
	);
}
