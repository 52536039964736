import React, { useState } from "react";
import Context from "./context";
import _ from "lodash";
import deepdash from "deepdash";
import formatname from "../utils/formatInputNumberName";
import { createDemande } from '../services/demande';
import { createDevis, getDevisById } from '../services/devis'
import { createAdress, updateAddressById } from "../services/addresse"
import moment from 'moment'
import { useNavigate } from "react-router-dom"
import axios from "axios";

deepdash(_);

const Provider = (props) => {
  const [prestation, setPrestation] = useState([]);
  const [inputs, setInputs] = useState(null);
  const [devis, setDevis] = useState({});
  const [showDevis, setShowDevis] = useState(false);
  const [famille, setFamille] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [totalHour, setTotalHour] = useState("");
  const [disponibiliteDate, setdisponibiliteDate] = useState({})
  const [creneauHoraire, setCreneauHoraire] = useState("")
  const [client, setClient] = useState(null)
  const [credentials, setCredentials] = useState(null)
  const [clientAuthInfo, setClientAuthInfo] = useState(null)
  const [prevStep, setPrevStep] = useState(null)
  const [codePromo, setCodePromo] = useState(null)
  const [modePaiement, setModePaiement] = useState(null)
  const [adresse, setAdresse] = useState()
  const [demandePerso, setDemandePerso] = useState(null)
  const [browserId, setBrowserId] = useState(null)
  const [count, setCount] = useState(0)
  const [commandeRef, setCommandeRef] = useState("")
  const hours = {
    "Matinée": { starthour: "9h00", endhour: "12h00" },
    "Pause Déj": { starthour: "12h00", endhour: "14h00" },
    "Après-midi": { starthour: "14h00", endhour: "19h00" },
  }

  const postDemande = async (path, user) => {
    let pathArray = []
    path && path.forEach(elm => {
      elm.libelle && pathArray.push(elm.libelle);
      elm.question && pathArray.push(elm.question);
    })
    const files = demandePerso?.fileUrls && demandePerso?.fileUrls?.map((f, i) => f);
    let comment = inputs ? (`Workflow chemin : ${pathArray.join(" - ")} ; ${inputs.map(input => input.value > 0 ? `${formatname(input.name) || input.question} ${input.value}` : null).join()}`) : (`Workflow chemin : ${pathArray.join(" - ")}`)
    let univers = prestation?.map((prest) => prest?.prestation?.services[0]) || []
    let demande = {
      nomClient: client?.nom || user?.nom,
      idClient: client?._id || user?._id,
      tele: client?.tele || user?.tele,
      email: client?.email || user?.email,
      files: files,
      disponibilite: {
        date: disponibiliteDate instanceof Date ? moment(disponibiliteDate).format("YYYY-MM-DD") : null,
        creneauHoraire: creneauHoraire || "",
        starthour: hours[creneauHoraire]?.starthour || "",
        endhour: hours[creneauHoraire]?.endhour || ""
      },
      commentaire: `${demandePerso?.commentaire || ""} -- ${comment}`,
      univers: univers,
      source: "Website"
    }
    console.log("demandePerso",demande)
    let newdemande = await createDemande(demande)
    console.log("deamndeRes",newdemande)
    newdemande && setDemandePerso({})

    return newdemande;
  }
  // const addFees = ()=>{
  
  //   //const exist = prestation?.find( p => p?.prestation?._id === "62ceb0fbf160c3d8b69eac9e" )
  //   setPrestation([{prestation:{...obj}}])
  //   console.log("prestation with fees",prestation)
  // }

  const createCommande = async (path) => {
    //addFees();
    const obj = {
      "_id": "62ceb0fbf160c3d8b69eac9e",
      "libelle": "Frais de déplacement",
      "Fees":50,
      "quantite":1,
      "description": "",
      "prixAchat":35,
      "Coefficient":1,
      "marge":30,
      "services":[],
      "nbrPres":1,
      "prixVente":50,
      "presForfaitaire": false,
    }
    let createdDemande = await postDemande(path)
    console.log("adresse",adresse)
    let createAddresse = adresse?._id ? await updateAddressById(adresse?._id, adresse) : await createAdress({ idClient: client._id, ...adresse, isPrincipal: false })

    let devis = {
      idAdresse: createAddresse?._id,
      idClient: client?._id,
      idDemande: createdDemande?._id,
      status: "Confirmé",
      prestations:[...prestation?.map(pres => ({ ...pres?.prestation, quantite: pres?.quantite, marge: 30 })), obj],
      modePaiement: modePaiement,
      dateCommande: moment(Date.now()).format(),
    }
    let newDevis = await createDevis(devis)
    let devisRef = await getDevisById(newDevis?._id)
    setCommandeRef(devisRef?.ref)
    if (newDevis._id) {
      clearContext()
      return newDevis
    }
  }

  const postDemandePerso = async (path, user) => {
    let pathArray = []
    path && path.forEach(elm => {
      elm.libelle && pathArray.push(elm.libelle);
      elm.question && pathArray.push(elm.question);
    })
    let comment = inputs ? (`Workflow chemin : ${pathArray?.join(" - ")} ; ${inputs.map(input => input.value > 0 ? `${formatname(input.name) || input.question} ${input.value}` : null).join()}`) : (`Workflow chemin : ${pathArray?.join(" - ")}`)
    console.log("commenatire",comment)
    const commenatire = `${demandePerso?.commentaire || ""} -- ${comment}`
    // let createAddresse = adresse?._id ? updateAddressById(adresse?._id, adresse) : await createAdress({ idClient: client._id, ...adresse, isPrincipal: false })
    console.log(commenatire)
    let demande = {
      ...demandePerso,
      nomClient: user?.nom,
      idClient: user?._id,
      tele: user?.tele,
      email: user?.email,
      univers: demandePerso?.services,
      commentaire:`${demandePerso?.commentaire || ""} -- Workflow chemin : ${pathArray?.join(" - ")}`,
      
    };
    console.log("demande d", demande);
    //post ticket
    if (demande) {
      setClient({ nom: demande?.nomClient })
      const files = demande?.fileUrls && demande?.fileUrls?.map((f, i) => f);
      console.log(files);
      // const ticketRes = await axios.post(
      //   "https://microservices.saweblia.ma:11111/createTicket",
      //   {
      //     fullName: `${demande?.nomClient}`,
      //     phone: `${demande?.tele || demandePerso?.tele}`,
      //     email: `${demande?.email || demandePerso?.email}`,
      //     subject: `${demande?.univers?.join(",") || ""}- ${demande?.city} - ${demande?.via || ""}`,
      //     body: `${demande?.tele || demandePerso?.tele} ${demande?.commentaire} > ${comment || ""} > ${files?.join(" , ") || ""}`,
      //   }
      // );
      // console.log({
      //   fullName: `${demande?.nomClient}`,
      //   phone: `${demande?.tele || demandePerso?.tele}`,
      //   email: `${demande?.email || demandePerso?.email}`,
      //   subject: `${demande?.tele || demandePerso?.tele} ${demande?.univers?.join(",") || ""}- ${demande?.city} - ${demande?.via || ""}`,
      //   body: `${demande?.commentaire} > ${comment || ""} > ${files.join(" , ")}`,
      // })
      // console.log("newDemande",demande)
      let newDemande = await createDemande(demande)
      console.log("newDemande",newDemande)
      
      return newDemande;
    }

    
   
  };


  const clearContext = () => {
    setPrestation([])
    setInputs([])
    setFamille("")
    setTotalPrice(0)
    setTotalHour(0)
    setdisponibiliteDate(null)
    setCreneauHoraire(null)
    setCodePromo("")
    setModePaiement("")
    setAdresse(null)
  }

  return (
    <Context.Provider
      value={{
        prestation: prestation,
        showDevis: showDevis,
        devis: devis,
        inputs: inputs,
        famille: famille,
        totalPrice: totalPrice,
        totalHour: totalHour,
        disponibiliteDate: disponibiliteDate,
        creneauHoraire: creneauHoraire,
        commandeRef,
        prevStep,
        client,
        clientAuthInfo,
        modePaiement,
        setModePaiement,
        setClientAuthInfo,
        credentials,
        adresse,
        codePromo,
        demandePerso,
        browserId,
        count,
        setCount,
        setBrowserId,
        setDemandePerso,
        setCredentials,
        setPrevStep,
        setClient,
        setAdresse,
        setPrestation,
        setselectedFamille: (selectedfamille) => {
          setFamille(selectedfamille)
        },
        setShowDevis: setShowDevis,
        setPrice: setTotalPrice,
        setTotalHour: setTotalHour,
        setInputs: (value) => { setInputs(value) },
        setdisponibiliteDate: setdisponibiliteDate,
        setCreneauHoraire: setCreneauHoraire,
        setCodePromo: setCodePromo,
        createCommande: createCommande,
        postDemandePerso,
        postDemande,
        clearContext,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default Provider;