import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SVG } from '../Assets/svg/contact.svg'
const Footer = () => {
  return (
    <footer className='bg-[#14166B] text-white text-sm py-2 px-6 md:px-20 h-max w-full'>
      <div className='container mx-auto max-w-[74rem]'>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-2">
          <div className='flex flex-col gap-1'>
            <span className='font-semibold'> A propos</span>
            <div className='flex flex-col ml-6 gap-1'>
              <a rel="noopener noreferrer" href='https://www.saweblia.ma/qui-sommes-nous.html'>Qui sommes-nous ?</a>
              <a rel="noopener noreferrer" href='https://www.saweblia.ma/charte.html'>Charte Qualité</a>
              <a rel="noopener noreferrer" href='https://www.saweblia.ma/contact.html'>Contact</a>
              {/* <a>Presse</a> */}
              <a rel="noopener noreferrer" href='https://www.saweblia.ma/cgu.html'>CGUs</a>
              {/* <a>Mentions légales</a> */}
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <span className='font-semibold'>Nos prestations</span>
            <div className='flex flex-col ml-6 gap-1'>
              <Link to={{ pathname: "/", search: "categorie=7671a9e1-f78e-11ec-b841-b365898f6960" }}>Remplacer une poignée de porte</Link>
              <Link to="/?categorie=3ffd0761-c8c8-4486-a8a1-5d25cd6cbc46">Panne de courant</Link>
              <Link to="/?categorie=7671a9e1-f78e-11ec-b841-b365898f6960">Fuite d’eau WC</Link>
              <Link to="/?prestation=628cf4429fd5e2f5788d7255">Raccorder ma machine à laver</Link>
              <Link to="/?categorie=7f0aacdc-f62d-11ec-94f6-5f09383eb1eb">Remplacer une ampoule</Link>
              <Link to="/?categorie=9b3969b5-fe07-11ec-805b-f38279ba2de9">Serrure bloquée</Link>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <span className='font-semibold'>Vos demandes</span>
            <div className='flex flex-col ml-6 gap-1'>
              <Link to={{ pathname: "/", search: "categorie=root-step" }}>Diagnostiquez votre problème</Link>
              <Link to={"/profil"}>Suivez votre commande</Link>
              {/* <span>Service Après-Vente</span> */}
              <a rel="noopener noreferrer" href='https://www.saweblia.ma/faq.html'>FAQ</a>
              <a href="https://wa.me/+212677330404/?text=Bonjour Saweblia, j'aimerais être contacté pour mes petits travaux !"><SVG /></a>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <span className='font-semibold'>Recrutement</span>
            <div className='flex flex-col ml-6 gap-1'>
              <a rel="noopener noreferrer" href='https://www.saweblia.ma/contact.html'>Rejoignez le réseau de Pros</a>
              <a rel="noopener noreferrer" href='https://www.saweblia.ma/contact.html'>Travaillez chez Saweblia</a>
            </div>
          </div>
        </div>
        <span className='my-3 flex justify-between'>
          Copyright © 2022 Saweblia.ma - Tous droits réservés
          <div className='sm:flex gap-2 hidden'>
            <a rel="noopener noreferrer" href="https://www.facebook.com/saweblia/">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className='h-5 w-5 stroke-white fill-transparent' strokeWidth={25}>
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>

            <a rel="noopener noreferrer" href="https://www.instagram.com/saweblia/">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='h-6 w-6 fill-white'>
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
            </a>

            <a rel="noopener noreferrer" href="https://www.linkedin.com/company/saweblia/mycompany/">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='h-6 w-6 stroke-white fill-transparent' strokeWidth={25}>
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
              </svg>
            </a>
          </div>
        </span>
      </div>
    </footer >
  );
}

export default Footer;
